/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { RentalOverviewRequest } from "./api";
import { SUPPORTED_LOCALES } from "./i18n/utils/locale.constants";
import { getRentalOverview } from "./slices/common/rental-overview-thunk";
import {
  setLocale,
  setThemeConfig,
  setWidgetId,
  ThemeConfig,
} from "./slices/config-slice";
import { store } from "./store";
import { sanitizeColor } from "./utils/sanitize-color";

type TopiConfig = {
  locale: string;
  widgetId: string;
  themeConfig: ThemeConfig;
};

class TopiElements {
  private _widgetId: string;

  private _locale: string;

  private _cartItems: RentalOverviewRequest[];

  private _pdpItem: RentalOverviewRequest;

  private _themeConfig: ThemeConfig;

  constructor(config: TopiConfig) {
    this.widgetId = config.widgetId;
    this.locale = config.locale;
    this.themeConfig = config.themeConfig;
  }

  set locale(localeString: string) {
    try {
      TopiElements.isValidLocale(localeString);
      this._locale = localeString;
      store.dispatch(setLocale(this.locale));
    } catch (error) {
      console.error(error);
    }
  }

  get locale() {
    return this._locale;
  }

  set widgetId(widgetId: string) {
    this._widgetId = widgetId;
    store.dispatch(setWidgetId(this.widgetId));
  }

  get widgetId() {
    return this._widgetId;
  }

  set cartItems(items) {
    try {
      TopiElements.isValidCartItemsFormat(items);
    } catch (error) {
      console.error(error);
    }

    this._cartItems = items;

    try {
      store.dispatch(
        getRentalOverview({ cartItems: this._cartItems, slice: "cart" })
      );
    } catch (error) {
      console.error(error);
    }
  }

  set pdpItem(item) {
    try {
      TopiElements.isValidCartItemsFormat([item]);
    } catch (error) {
      console.error(error);
    }

    this._pdpItem = item;

    try {
      store.dispatch(
        getRentalOverview({ cartItems: [this._pdpItem], slice: "pdp" })
      );
    } catch (error) {
      console.error(error);
    }
  }

  set themeConfig(themeConfig: ThemeConfig) {
    if (!this._themeConfig) {
      this._themeConfig = { primaryColor: undefined };
    }

    this._themeConfig = themeConfig;

    if (themeConfig?.primaryColor) {
      this._themeConfig.primaryColor = sanitizeColor(themeConfig.primaryColor);
    }

    store.dispatch(setThemeConfig(this.themeConfig));
  }

  get themeConfig() {
    return this._themeConfig;
  }

  // eslint-disable-next-line class-methods-use-this
  addEventListener(
    eventType: string,
    callback: (event: Event) => void
  ): AbortController {
    const controller = new AbortController();

    document.addEventListener(eventType, callback, {
      signal: controller.signal,
    });

    // Provided for the user to call controller.abort() to remove the listener for cleanup,
    // which is simpler than calling removeEventListener with the same arguments.
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener
    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    return controller;
  }

  static isValidCartItemsFormat(items): boolean {
    if (!Array.isArray(items)) {
      throw new TypeError("items must be an array");
    }

    items.forEach((item) => {
      if (typeof item !== "object") {
        throw new TypeError("items must be an array of objects");
      }

      if (
        !Object.prototype.hasOwnProperty.call(item, "sellerProductReference")
      ) {
        throw new Error("items must have a sellerProductReference property");
      }

      if (!Object.prototype.hasOwnProperty.call(item, "quantity")) {
        throw new Error("items must have a quantity property");
      }

      if (typeof item.quantity !== "number") {
        throw new TypeError("quantity must be a number");
      }
    });

    return true;
  }

  static isValidLocale(localeString: string): boolean {
    if (typeof localeString !== "string") {
      throw new TypeError("locale must be a string");
    }
    if (!SUPPORTED_LOCALES.includes(localeString)) {
      throw new Error(
        `locale "${localeString}" is not supported. Supported locales: ${SUPPORTED_LOCALES.join(
          ", "
        )}`
      );
    }

    return true;
  }
}

export default TopiElements;
