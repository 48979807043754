/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SellerProductReference interface.
 */
export function instanceOfSellerProductReference(value) {
    let isInstance = true;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "source" in value;
    return isInstance;
}
export function SellerProductReferenceFromJSON(json) {
    return SellerProductReferenceFromJSONTyped(json, false);
}
export function SellerProductReferenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reference': json['reference'],
        'source': json['source'],
    };
}
export function SellerProductReferenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reference': value.reference,
        'source': value.source,
    };
}
