import { pxScaled } from "$/ui/utils/px-scaled";

export const DEFAULT_FONT_SIZE = "16px";

export const breakpoints = {
  s: 400,
  m: 800,
  l: 1200,
  xl: 1440,
} as const;

export type Breakpoint = keyof typeof breakpoints;

export const colors = {
  // Brand colors
  topiBlue05: "#E5EAFF",
  topiBlue10: "#CCD5FF",
  topiBlue30: "#6680FF",
  topiBlue40: "#3355FF",
  topiBlue50: "#002BFF",
  topiBlue60: "#0022CC",
  topiBlue70: "#001A99",

  topiPink10: "#FFCCE0",
  topiPink50: "#FF0062",

  // Grey scale
  white: "#FFFFFF",
  black: "#000000",

  grey04: "#F2F4F8",
  grey10: "#DFE4EC",
  grey15: "#D2D7DF",
  grey20: "#C6CBD2",
  grey30: "#ACB1B9",
  grey40: "#93989F",
  grey60: "#6B6F75",
  grey80: "#303236",
  grey90: "#18191B",

  // State colors
  green05: "#EDF8F5",
  green10: "#DBF0EB",
  green70: "#009974",
  yellow05: "#FFF4E5",
  yellow10: "#FFE8CC",
  yellow50: "#FF8A00",
  red05: "#FDE8E8",
  red10: "#FAD1D1",
  red40: "#EB4747",
  red50: "#E61919",
  red60: "#C80404",
  red70: "#990800",

  // Alpha
  alpha04: "rgba(24, 25, 27, 0.04)",
  alpha08: "rgba(24, 25, 27, 0.08)",
  alpha20: "rgba(0, 9, 51, 0.2)",
  alpha40: "rgba(0, 9, 51, 0.4)",
} as const;

export type Color = keyof typeof colors;

export type Colors = (typeof colors)[Color];

export const sizes = {
  sz002: pxScaled(2),
  sz004: pxScaled(4),
  sz008: pxScaled(8),
  sz012: pxScaled(12),
  sz016: pxScaled(16),
  sz020: pxScaled(20),
  sz024: pxScaled(24),
  sz032: pxScaled(32),
  sz036: pxScaled(36),
  sz040: pxScaled(40),
  sz044: pxScaled(44),
  sz048: pxScaled(48),
  sz056: pxScaled(56),
  sz064: pxScaled(64),
  sz080: pxScaled(80),
  sz096: pxScaled(96),
  sz128: pxScaled(128),
  sz200: pxScaled(200),
  sz240: pxScaled(240),
  sz260: pxScaled(260),
  sz312: pxScaled(312),
  sz400: pxScaled(400),
  sz480: pxScaled(480),
  sz644: pxScaled(644),
  sz866: pxScaled(866),
  sz1040: pxScaled(1040),
} as const;

export type Size = keyof typeof sizes;

export const spacing = {
  sp001: pxScaled(1),
  sp002: pxScaled(2),
  sp004: pxScaled(4),
  sp008: pxScaled(8),
  sp012: pxScaled(12),
  sp016: pxScaled(16),
  sp020: pxScaled(20),
  sp024: pxScaled(24),
  sp028: pxScaled(28),
  sp032: pxScaled(32),
  sp036: pxScaled(36),
  sp040: pxScaled(40),
  sp044: pxScaled(44),
  sp048: pxScaled(48),
  sp056: pxScaled(56),
  sp064: pxScaled(64),
  sp080: pxScaled(80),
  sp096: pxScaled(96),
  sp128: pxScaled(128),
} as const;

export type Spacing = keyof typeof spacing;

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
} as const;

export type FontWeight = keyof typeof fontWeights;

export const topiFont = "/fonts/readex-pro.ttf";

export const fontFaces = {
  primary:
    "'Readex Pro', 'DM Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  code: "Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace",
} as const;

export type FontFace = keyof typeof fontFaces;

export const letterSpacing = {
  xxl: "0.04em",
  xl: "-0.024em",
  l: "-0.016em",
  m: "-0.012em",
  s: "-0.008em",
  xs: "-0.004em",
} as const;

export type LetterSpacing = keyof typeof letterSpacing;

export const typography = {
  headline1: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(96),
    "line-height": 1,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.xl,
  },
  headline1small: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(32),
    "line-height": 1.125,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.xl,
  },
  headline2: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(32),
    "line-height": 1.125,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.l,
  },
  headline2small: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(24),
    "line-height": 1.16,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.l,
  },
  headline3: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(20),
    "line-height": 1.2,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.m,
  },
  headline4: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(16),
    "line-height": 1.25,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.s,
  },
  overline: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(11),
    "line-height": 1.45,
    "font-weight": fontWeights.regular,
    "letter-spacing": letterSpacing.xxl,
    "text-transform": "uppercase",
  },
  body1Emphasis: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(14),
    "line-height": 1.4,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.xs,
  },
  body1: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(14),
    "line-height": 1.4,
    "font-weight": fontWeights.light,
    "letter-spacing": letterSpacing.xs,
  },
  body2Emphasis: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(12),
    "line-height": 1.3,
    "font-weight": fontWeights.medium,
    "letter-spacing": letterSpacing.xs,
  },
  body2: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(12),
    "line-height": 1.3,
    "font-weight": fontWeights.light,
    "letter-spacing": letterSpacing.xs,
  },
  captionEmphasis: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(10),
    "line-height": 1.6,
    "font-weight": fontWeights.medium,
  },
  caption: {
    "font-family": fontFaces.primary,
    "font-size": pxScaled(10),
    "line-height": 1.6,
    "font-weight": fontWeights.light,
  },
} as const;

export type Typography = keyof typeof typography;

export const borderWidths = {
  xs: pxScaled(0.5),
  sm: pxScaled(1),
  md: pxScaled(2),
} as const;

export type BorderWidth = keyof typeof borderWidths;

export const borderRadius = {
  xs: pxScaled(2),
  s: pxScaled(4),
  m: pxScaled(8),
  l: pxScaled(12),
  xl: pxScaled(16),
  xxl: pxScaled(24),
  rounded: "50%",
} as const;

export type BorderRadius = keyof typeof borderRadius;

export const blurRadius = {
  overlay: pxScaled(8),
} as const;

export type BlurRadius = keyof typeof blurRadius;

export const transitions = {
  fast: "100ms ease",
  medium: "200ms ease",
} as const;

export type Transition = keyof typeof transitions;

export const shadows = {
  xs: `0 ${pxScaled(1)} ${pxScaled(2)} ${pxScaled(-2)} ${
    colors.alpha04
  }, 0 ${pxScaled(1)} ${pxScaled(4)} ${colors.alpha04}`,
  s: `0 ${pxScaled(1)} ${pxScaled(4)} ${pxScaled(-2)} ${
    colors.alpha04
  }, 0 ${pxScaled(2)} ${pxScaled(8)} ${colors.alpha04}`,
  m: `0 ${pxScaled(2)} ${pxScaled(8)} ${pxScaled(-2)} ${
    colors.alpha04
  }, 0 ${pxScaled(4)} ${pxScaled(16)} ${colors.alpha04}`,
  l: `0 ${pxScaled(4)} ${pxScaled(16)} ${pxScaled(-2)} ${
    colors.alpha04
  }, ${pxScaled(4)} ${pxScaled(12)} ${pxScaled(32)} ${colors.alpha04}`,
  xl: `0 ${pxScaled(8)} ${pxScaled(32)} ${pxScaled(-2)} ${
    colors.alpha04
  }, 0 ${pxScaled(24)} ${pxScaled(64)} ${colors.alpha04}`,
} as const;

export type Shadow = keyof typeof shadows;

export const backgroundImages = {
  roundedCheckmark:
    "url(\"data:image/svg+xml;utf8,<svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L4.32955 8L1 4.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>\")",
  checkmark:
    "url(\"data:image/svg+xml;utf8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 10.5L8.5 13L14.5 7' stroke='white'/></svg>\")",
} as const;

export type BackgroundImage = keyof typeof backgroundImages;

export const times = {
  slow: 5000,
  medium: 2000,
  fast: 1000,
  superfast: 500,
};

export type Time = keyof typeof times;

export const zIndices = {
  mobileMenu: 1,
  modal: 2_147_483_647,
} as const;
