import styled from "styled-components";

import {
  setTopLevelSharedCssVariables,
  setTypographyImportant,
} from "$/ui/styles/mixins";
import { colors, spacing } from "$/ui/styles/tokens/tokens";

export const Container = styled.div`
  ${setTopLevelSharedCssVariables()};

  ${setTypographyImportant("body1")};
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  gap: ${spacing.sp002} ${spacing.sp008} !important;
`;

export const Item = styled.div`
  padding: 0 !important;
  margin: 0 !important;
`;

export const LinkButton = styled.button`
  ${setTypographyImportant("body1")};
  text-decoration: underline !important;
  color: ${colors.black} !important;
  cursor: pointer;
  background: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
`;

export const TextAndLogo = styled.div`
  ${setTypographyImportant("body1")};
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  gap: ${spacing.sp008} !important;
`;
