import { css, FlattenSimpleInterpolation } from "styled-components";

import BackgroundSVG from "$/ui/assets/backgrounds/bg-full-screen-text-box-layout.svg";
import { from } from "$/ui/styles/media-queries";
import { pxScaled } from "$/ui/utils/px-scaled";
import {
  borderRadius,
  borderWidths,
  colors,
  DEFAULT_FONT_SIZE,
  spacing,
  typography,
  Typography,
} from "./tokens/tokens";

/**
 * Sets CSS variables on parent-most element that children elements will reference.
 *
 * CSS variables set externally by widget users:
 * * --topi-font-size-base: The font size in pixels, which can be set by widget users on the host site.
 *
 * CSS variables set internally:
 * * --default-font-size-base: The default font size in pixels.
 * * --font-size-base: The derived font size in pixels that is used throughout the application as a base.
 */
export const setTopLevelSharedCssVariables =
  (): FlattenSimpleInterpolation => css`
    --default-font-size-base: ${DEFAULT_FONT_SIZE};
    --font-size-base: var(--topi-font-size-base, var(--default-font-size-base));
  `;

export const setTypography = (category: Typography): string => `
  ${Object.entries(typography[category])
    .map(([key, value]) => `${key}: ${value};`)
    .join("\n")}
`;

export const setTypographyImportant = (category: Typography): string => `
  ${Object.entries(typography[category])
    .map(([key, value]) => `${key}: ${value} !important;`)
    .join("\n")}
`;

export const screenReaderOnly = (): FlattenSimpleInterpolation => css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const WRAPPER_WIDTH = 1170;

export const maxContent = css`
  margin: 0 auto;
  max-width: ${pxScaled(WRAPPER_WIDTH)};
  width: 100%;
  padding: ${spacing.sp032} ${spacing.sp016};

  ${from.m} {
    padding: ${spacing.sp032} ${spacing.sp024};
  }

  ${from.l} {
    padding: ${spacing.sp032} ${spacing.sp040};
  }
`;

export const maxContentWidth = css`
  width: 100%;
  ${from.xl} {
    margin: 0 auto;
    max-width: ${pxScaled(1280)};
  }
`;

/*
  linesClamp is a function that takes a number of lines as an argument and returns a CSS string that
  will truncate text to that number of lines. With this function you don't need to worry about using a
  hardcoded max-width and no-wrap on your text becauses is not necessary.
*/

export const linesClamp = (lines = 1): FlattenSimpleInterpolation => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const BG_SIZE_S = `${pxScaled(1211)} ${pxScaled(1165)}`;
const BG_SIZE_M_L_XL = `${pxScaled(2095)} ${pxScaled(2014)}`;

const BG_POSITION_S = `25vw ${pxScaled(-90)}`;
const BG_POSITION_M = `40vw ${pxScaled(-325)}`;
const BG_POSITION_L = `60vw ${pxScaled(-450)}`;
const BG_POSITION_XL = `52vw ${pxScaled(-450)}`;

export const colorfulBackground = (): FlattenSimpleInterpolation => css`
  background-image: url(${BackgroundSVG});
  background-size: ${BG_SIZE_S};
  /* This is not RTL friendly */
  background-repeat: no-repeat;
  background-position: ${BG_POSITION_S};

  ${from.m} {
    background-size: ${BG_SIZE_M_L_XL};
    background-position: ${BG_POSITION_M};
  }

  ${from.l} {
    background-position: ${BG_POSITION_L};
    background-attachment: fixed;
  }

  ${from.xl} {
    background-position: ${BG_POSITION_XL};
  }
`;

export const whiteCardWithRoundedBorders =
  (): FlattenSimpleInterpolation => css`
    padding: ${spacing.sp048} ${spacing.sp024};
    border-radius: ${borderRadius.xxl};
    background-color: ${colors.white};
    text-align: center;
    /* To allow newline translation characters */
    white-space: pre-line;

    ${from.l} {
      padding: ${spacing.sp064} ${spacing.sp064} ${spacing.sp048};
    }
  `;

export const topiOutline = (): FlattenSimpleInterpolation => css`
  outline: none;
  box-shadow: 0 0 0 ${borderWidths.md} ${colors.topiBlue30};
`;
