/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable no-console */
import { FC } from "preact/compat";
import { useErrorBoundary } from "preact/hooks";

interface TopiWidgetsErrorBoundaryProps {
  children: JSX.Element;
}

const ErrorBoundary: FC<TopiWidgetsErrorBoundaryProps> = ({ children }) => {
  // TODO PHNX-339: Track production widget errors somewhere
  const [error] = useErrorBoundary();

  if (error) {
    console.error(error);
    return undefined;
  }

  return children;
};

export default ErrorBoundary;
