import styled from "styled-components";

import { setTypographyImportant } from "$/ui/styles/mixins";
import LogoBadge from "../../common/logo-badge/logo-badge";
import { Logo } from "../../common/logo-badge/logo-badge.styles";

import { setTopLevelSharedCssVariables } from "../../ui/styles/mixins";
import { borderRadius, colors, spacing } from "../../ui/styles/tokens/tokens";
import { pxScaled } from "../../ui/utils/px-scaled";

const CHECKOUT_BUTTON_MIN_WIDTH = 192;
const CHECKOUT_BUTTON_TOPI_LOGO_WIDTH = 37.5;

export const ProductModeButton = styled(LogoBadge)`
  ${setTopLevelSharedCssVariables()};

  min-width: ${pxScaled(CHECKOUT_BUTTON_MIN_WIDTH)} !important;
  &:hover {
    background-color: ${colors.grey80} !important;
  }

  &:active {
    background-color: ${colors.black} !important;
  }

  &:focus-visible {
    outline-color: ${colors.topiBlue30} !important;
  }
  cursor: pointer !important;

  ${Logo} {
    width: ${pxScaled(CHECKOUT_BUTTON_TOPI_LOGO_WIDTH)} !important;
  }
`;

export const CartModeButton = styled("button")`
  ${setTopLevelSharedCssVariables()};

  ${setTypographyImportant("headline4")};
  color: ${colors.white} !important;

  display: block !important;
  box-sizing: border-box !important;
  background-color: ${colors.grey90} !important;
  width: 100% !important;
  padding: ${spacing.sp012} ${spacing.sp024} !important;
  border: none !important;
  border-radius: ${borderRadius.s} !important;
  min-width: ${pxScaled(CHECKOUT_BUTTON_MIN_WIDTH)} !important;

  &:hover {
    background-color: ${colors.grey80} !important;
  }

  &:active {
    background-color: ${colors.black} !important;
  }

  &:focus-visible {
    outline-color: ${colors.topiBlue30} !important;
  }
  cursor: pointer !important;
`;
