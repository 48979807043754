/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ParseOfferTextRequestBody interface.
 */
export function instanceOfParseOfferTextRequestBody(value) {
    let isInstance = true;
    isInstance = isInstance && "sourceText" in value;
    return isInstance;
}
export function ParseOfferTextRequestBodyFromJSON(json) {
    return ParseOfferTextRequestBodyFromJSONTyped(json, false);
}
export function ParseOfferTextRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sourceText': json['source_text'],
    };
}
export function ParseOfferTextRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'source_text': value.sourceText,
    };
}
