/* eslint-disable no-console */
import { Mode } from "../../../common/models";

export function useDebugMessages(mode?: Mode, reference?: string): void {
  if (!mode) {
    console.error(
      "invalid/missing required attribute `mode` in the cart rental summary label element. Valid values: 'cart', 'product'"
    );
  }

  if (mode === "product" && !reference) {
    console.error(
      "invalid/missing required attribute `reference` in the cart rental summary label element when `mode='product'`. Please add `reference` as an attribute to render prices for a specific product in the cart."
    );
  }
}
