import { Fragment } from "preact";
import { Provider as ReduxProvider, useSelector } from "react-redux";

import { MarkupText, Text } from "preact-i18n";
import { useState } from "preact/hooks";

import ErrorBoundary from "../../common/error-boundary/error-boundary";
import LogoBadge from "../../common/logo-badge/logo-badge";
import I18nProvider from "../../i18n/i18n-provider";
import { getLocaleConfig } from "../../slices/config-slice";
import { getFetchedRentalOverviewForProduct } from "../../slices/pdp-slice";
import { store } from "../../store";
import { useLowestRentalPrice } from "../hooks/use-lowest-rental-price";
import { useMoneyAmount } from "../hooks/use-money-amount";
import { LearnMoreModal } from "../common/learn-more-modal/learn-more-modal";
import {
  Container,
  Item,
  LinkButton,
  TextAndLogo,
} from "./product-rental-summary-label.styles";
import useCleanUpNestedFonts from "$/use-cleanup-nested-fonts";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ProductRentalSummaryLabelImpl() {
  const localeConfig = useSelector(getLocaleConfig);

  const rentalOverviewForPdp = useSelector(getFetchedRentalOverviewForProduct);
  const lowestPrice = useLowestRentalPrice(rentalOverviewForPdp);

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = (event: Event) => {
    event.preventDefault();
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  const price = useMoneyAmount(lowestPrice, "EUR");

  // Fix duplicated font nodes when using google translate
  useCleanUpNestedFonts();

  return price ? (
    <I18nProvider locale={localeConfig}>
      <Container>
        <Item>
          <MarkupText id="rentalSummaryLabel.rentFrom" fields={{ price }} />
        </Item>
        <Item>
          <TextAndLogo>
            <Text id="rentalSummaryLabel.rentWith" />
            <LogoBadge nonFullWidth />
          </TextAndLogo>
        </Item>
        <LinkButton onClick={openModal} type="button">
          <Text id="learnMore" />
        </LinkButton>
      </Container>
      {isModalOpen && <LearnMoreModal mode="product" onClose={closeModal} />}
    </I18nProvider>
  ) : undefined;
}

export default function ProductRentalSummaryLabel() {
  return (
    <Fragment>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <ProductRentalSummaryLabelImpl />
        </ErrorBoundary>
      </ReduxProvider>
    </Fragment>
  );
}
