import { Text } from "preact-i18n";
import { useSelector } from "react-redux";
import { getThemeConfig } from "../../../../slices/config-slice";
import { Container, TenureToggle } from "./tenure-toggle-group.styles";

export function TenureToggleGroup({
  availableRentDurations,
  selectedTenure,
  setSelectedTenure,
  isWide,
}) {
  const themeConfig = useSelector(getThemeConfig);
  return (
    <Container role="tablist" aria-label="Tenure option tabs">
      {availableRentDurations.map((tenure) => {
        return (
          <TenureToggle
            id={`tab-tenure-${tenure}`}
            role="tab"
            aria-selected={selectedTenure === tenure}
            tabIndex={selectedTenure === tenure ? 0 : -1}
            aria-controls={`tabpanel-tenure-${tenure}`}
            type="button"
            key={tenure}
            isSelected={selectedTenure === tenure}
            onClick={() => setSelectedTenure(tenure)}
            primaryColor={themeConfig?.primaryColor}
          >
            <span>
              <Text
                id={
                  isWide
                    ? "rentalOverview.tenureInMonths"
                    : "rentalOverview.tenureInMonthsShort"
                }
                fields={{ tenure }}
              />
            </span>
          </TenureToggle>
        );
      })}
    </Container>
  );
}
