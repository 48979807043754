// circular dependency may need fixing, but disabling linter works for now
/* eslint-disable import/no-cycle */
import {
  configureStore,
  combineReducers,
  PreloadedState,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import configReducer from "./slices/config-slice";
import cartReducer from "./slices/cart-slice";
import pdpReducer from "./slices/pdp-slice";
import { api } from "./api";

const rootReducer = combineReducers({
  config: configReducer,
  cart: cartReducer,
  pdp: pdpReducer,
});

export type RootStateWidgets = ReturnType<typeof rootReducer>;

export function setupWidgetsRedux(
  preloadedState?: PreloadedState<RootStateWidgets>
) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: api,
        },
      });
    },
  });
  return store;
}

export const store = setupWidgetsRedux();

// Infer `RootState` and `AppDispatch` from the store itself
export type AppDispatchWidgets = typeof store.dispatch;
export type StoreTypeWidgets = typeof store;
export const useAppDispatchWidgets = () => useDispatch<AppDispatchWidgets>();
