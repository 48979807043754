/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { RentContractTermFromJSON, RentContractTermToJSON, } from './RentContractTerm';
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * @export
 */
export const CartProductRentalOverviewCurrencyEnum = {
    Eur: 'EUR'
};
/**
 * Check if a given object implements the CartProductRentalOverview interface.
 */
export function instanceOfCartProductRentalOverview(value) {
    let isInstance = true;
    isInstance = isInstance && "hasRentalTerms" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "sellerProductReference" in value;
    return isInstance;
}
export function CartProductRentalOverviewFromJSON(json) {
    return CartProductRentalOverviewFromJSONTyped(json, false);
}
export function CartProductRentalOverviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableRentalTerms': !exists(json, 'available_rental_terms') ? undefined : (json['available_rental_terms'].map(RentContractTermFromJSON)),
        'canCheckout': !exists(json, 'can_checkout') ? undefined : json['can_checkout'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'hasRentalTerms': json['has_rental_terms'],
        'quantity': json['quantity'],
        'sellerProductReference': SellerProductReferenceFromJSON(json['seller_product_reference']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'totalRentalAmount': !exists(json, 'total_rental_amount') ? undefined : json['total_rental_amount'],
    };
}
export function CartProductRentalOverviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'available_rental_terms': value.availableRentalTerms === undefined ? undefined : (value.availableRentalTerms.map(RentContractTermToJSON)),
        'can_checkout': value.canCheckout,
        'currency': value.currency,
        'has_rental_terms': value.hasRentalTerms,
        'quantity': value.quantity,
        'seller_product_reference': SellerProductReferenceToJSON(value.sellerProductReference),
        'summary': value.summary,
        'total_rental_amount': value.totalRentalAmount,
    };
}
