import { h } from "preact";
import { Icon } from "$/ui/components/icon/icon";
import { ModalWidth } from "./modal-width-context";
import {
  Button,
  CloseButton,
  Footer,
  ModalBackdrop,
  ModalContent,
} from "./modal.styles";

export type ModalProps = {
  onClose?: () => void;
  isOpen?: boolean;
  isDismissible?: boolean;
  width?: ModalWidth;
  children: h.JSX.Element | h.JSX.Element[];
  footerButtonAction?: () => void;
  footerButtonText?: JSX.Element;
};

const Modal = ({
  children,
  onClose,
  isDismissible,
  width = "m",
  footerButtonAction,
  footerButtonText,
}: ModalProps) => {
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget && isDismissible && onClose) {
      onClose();
    }
  };

  return (
    <ModalBackdrop
      onClick={handleBackdropClick}
      role="dialog"
      aria-modal="true"
    >
      <ModalContent width={width}>
        {isDismissible && onClose && (
          <CloseButton onClick={onClose}>
            <Icon name={"cross"} />
          </CloseButton>
        )}
        {children}

        {footerButtonAction && footerButtonText ? (
          <Footer>
            <Button onClick={footerButtonAction}>{footerButtonText}</Button>
          </Footer>
        ) : undefined}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default Modal;
