import { Text } from "preact-i18n";

import { RentContractTermDurationEnum } from "$/api";
import { ThemeConfig } from "$/slices/config-slice";
import { AggregatedPrices } from "$/widgets/hooks/use-aggregated-prices";
import { useMoneyAmount } from "$/widgets/hooks/use-money-amount";
import {
  EligibilityInfoPill,
  PriceAmount,
  PriceUnit,
  PriceTabPanel,
  TaxNote,
  Container,
} from "./tenure-prices.styles";

type TenurePricesProps = {
  aggregatedPrices?: AggregatedPrices;
  availableRentDurations?: RentContractTermDurationEnum[];
  cartHasRentalTerms: boolean;
  selectedTenure?: RentContractTermDurationEnum;
  themeConfig: ThemeConfig;
};

const PriceInfo = ({
  cartHasRentalTerms,
  duration,
  isSelected,
  monthlyNet,
  themeConfig,
  total,
}) => {
  const rentMonthlyPrice = useMoneyAmount(monthlyNet, "EUR");
  const rentTotalPrice = useMoneyAmount(total, "EUR");

  return rentMonthlyPrice && rentTotalPrice ? (
    <PriceTabPanel
      hidden={!isSelected}
      id={`tabpanel-tenure-${duration}`}
      role="tabpanel"
      aria-labelledby={`tab-tenure-${duration}`}
      tabIndex={0}
    >
      <PriceAmount primaryColor={themeConfig?.primaryColor}>
        {rentMonthlyPrice}
        <PriceUnit primaryColor={themeConfig?.primaryColor}>
          <Text id="rentalOverview.perMonth" />
        </PriceUnit>
      </PriceAmount>

      {cartHasRentalTerms ? undefined : (
        <EligibilityInfoPill>
          <Text id="rentalOverview.eligibilityInfo" />
        </EligibilityInfoPill>
      )}

      <TaxNote>
        <Text id="rentalOverview.taxNote" />
      </TaxNote>
    </PriceTabPanel>
  ) : undefined;
};

export function TenurePrices({
  aggregatedPrices,
  availableRentDurations,
  cartHasRentalTerms,
  selectedTenure,
  themeConfig,
}: TenurePricesProps) {
  // Note: Always render all panels but hide inactive ones, so that browser translations
  // will pick them as DOM nodes for translation correctly.
  return availableRentDurations ? (
    <Container>
      {availableRentDurations.map((duration) => {
        const monthlyAmount = aggregatedPrices[duration];
        const monthlyNet = monthlyAmount.net;
        const total = monthlyNet * duration;
        const isSelected = duration === selectedTenure;

        return (
          <PriceInfo
            key={duration}
            duration={duration}
            isSelected={isSelected}
            themeConfig={themeConfig}
            monthlyNet={monthlyNet}
            total={total}
            cartHasRentalTerms={cartHasRentalTerms}
          />
        );
      })}
    </Container>
  ) : undefined;
}
