import styled from "styled-components";

import { Icon as DefaultIcon } from "$/ui/components/icon/icon";
import {
  setTopLevelSharedCssVariables,
  setTypographyImportant,
} from "$/ui/styles/mixins";
import { colors, sizes, spacing } from "$/ui/styles/tokens/tokens";

export const Container = styled.div`
  ${setTopLevelSharedCssVariables()};

  ${setTypographyImportant("body1")};
  display: flex !important;
  gap: ${spacing.sp016} !important;
  max-width: ${sizes.sz866} !important;

  & > * {
    flex: 1 !important;
    min-width: ${sizes.sz200} !important;
  }

  @media (max-width: 523px) {
    flex-direction: column !important;
  }

  @media (min-width: 524px) {
    flex-direction: row !important;
  }
`;

export const RentalInfo = styled.div`
  display: flex !important;
  flex-direction: column !important;
  gap: ${spacing.sp008} !important;

  & > * {
    margin: 0 !important;
  }
  justify-content: center !important;
  align-items: left !important;
`;

export const Title = styled.h4`
  ${setTypographyImportant("headline3")};
`;

export const Body = styled.p`
  ${setTypographyImportant("body1")};
`;

export const LinkButton = styled.button`
  ${setTypographyImportant("body2Emphasis")};
  text-decoration: none !important;
  color: ${colors.topiBlue50} !important;
  cursor: pointer;
  background: none !important;
  border: none !important;
  margin: ${spacing.sp008} 0 0 0 !important;
  padding: 0 !important;
  align-self: flex-start !important;
`;

export const UnorderedList = styled.ul`
  padding: 0 !important;
  margin: 0 !important;
  list-style-type: none !important;
  & > *:not(:last-child) {
    margin-bottom: ${spacing.sp008} !important;
  }
`;

export const ListItem = styled.li`
  display: flex !important;
  gap: ${spacing.sp008} !important;
  align-items: flex-start !important;
`;

export const Icon = styled(DefaultIcon)`
  min-width: ${sizes.sz016} !important;
  height: ${sizes.sz016} !important;
  color: ${colors.green70} !important;
  margin-top: ${spacing.sp002} !important;
`;
