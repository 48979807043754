/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ExtraProductDetailsFromJSON, ExtraProductDetailsToJSON, } from './ExtraProductDetails';
import { MoneyAmountFromJSON, MoneyAmountToJSON, } from './MoneyAmount';
import { MoneyAmountWithOptionalTaxFromJSON, MoneyAmountWithOptionalTaxToJSON, } from './MoneyAmountWithOptionalTax';
import { ProductImageFromJSON, ProductImageToJSON, } from './ProductImage';
import { ProductSellerCategoryFromJSON, ProductSellerCategoryToJSON, } from './ProductSellerCategory';
import { ProductStandardIdentifierFromJSON, ProductStandardIdentifierToJSON, } from './ProductStandardIdentifier';
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * @export
 */
export const AddProductSellerProductTypeEnum = {
    Hardware: 'hardware',
    Software: 'software',
    Service: 'service',
    HardwareBuiltToOrder: 'hardware_built_to_order'
};
/**
 * Check if a given object implements the AddProduct interface.
 */
export function instanceOfAddProduct(value) {
    let isInstance = true;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "sellerProductReferences" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function AddProductFromJSON(json) {
    return AddProductFromJSONTyped(json, false);
}
export function AddProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'baseRentalPrice': !exists(json, 'base_rental_price') ? undefined : MoneyAmountFromJSON(json['base_rental_price']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'extraDetails': !exists(json, 'extra_details') ? undefined : (json['extra_details'].map(ExtraProductDetailsFromJSON)),
        'image': !exists(json, 'image') ? undefined : ProductImageFromJSON(json['image']),
        'isActive': json['is_active'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'price': MoneyAmountWithOptionalTaxFromJSON(json['price']),
        'productStandardIdentifiers': !exists(json, 'product_standard_identifiers') ? undefined : (json['product_standard_identifiers'].map(ProductStandardIdentifierFromJSON)),
        'sellerCategories': !exists(json, 'seller_categories') ? undefined : (json['seller_categories'].map(ProductSellerCategoryFromJSON)),
        'sellerProductReferences': (json['seller_product_references'].map(SellerProductReferenceFromJSON)),
        'sellerProductType': !exists(json, 'seller_product_type') ? undefined : json['seller_product_type'],
        'shopProductDescriptionUrl': !exists(json, 'shop_product_description_url') ? undefined : json['shop_product_description_url'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'title': json['title'],
    };
}
export function AddProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'base_rental_price': MoneyAmountToJSON(value.baseRentalPrice),
        'description': value.description,
        'extra_details': value.extraDetails === undefined ? undefined : (value.extraDetails.map(ExtraProductDetailsToJSON)),
        'image': ProductImageToJSON(value.image),
        'is_active': value.isActive,
        'manufacturer': value.manufacturer,
        'price': MoneyAmountWithOptionalTaxToJSON(value.price),
        'product_standard_identifiers': value.productStandardIdentifiers === undefined ? undefined : (value.productStandardIdentifiers.map(ProductStandardIdentifierToJSON)),
        'seller_categories': value.sellerCategories === undefined ? undefined : (value.sellerCategories.map(ProductSellerCategoryToJSON)),
        'seller_product_references': (value.sellerProductReferences.map(SellerProductReferenceToJSON)),
        'seller_product_type': value.sellerProductType,
        'shop_product_description_url': value.shopProductDescriptionUrl,
        'subtitle': value.subtitle,
        'title': value.title,
    };
}
