import { createContext } from "preact";

import { sizes } from "$/ui/styles/tokens/tokens";

export const modalWidth = {
  s: { widthM: sizes.sz480, widthL: sizes.sz480, widthXL: sizes.sz480 },
  m: { widthM: sizes.sz644, widthL: sizes.sz644, widthXL: sizes.sz644 },
  l: { widthM: sizes.sz644, widthL: sizes.sz866, widthXL: sizes.sz866 },
  xl: { widthM: sizes.sz866, widthL: sizes.sz1040, widthXL: sizes.sz1040 },
} as const;

export type ModalWidth = keyof typeof modalWidth;

export interface ModalWidthContextData {
  selectedWidth: ModalWidth;
}

export const DEFAULT_MODAL_WIDTH = "s";

export const ModalWidthContext = createContext<ModalWidthContextData>({
  selectedWidth: DEFAULT_MODAL_WIDTH,
});
