// TODO:  decouple import of api client from `front` repo by copying over the
//        generated Seller API client to this repo (when widgets has its own repo)
import {
  CatalogApi as SellerCatalogApi,
  Configuration,
} from "seller-api-client";

// Note: Widgets API is part of Seller API.
const initialWidgetsConfiguration = new Configuration({
  basePath: process.env.WIDGETS_API_URL_ORIGIN,
});

export const api = {
  sellerApi: {
    catalog: new SellerCatalogApi(initialWidgetsConfiguration),
  },
};

export type ApiType = typeof api;

export {
  RentalOverviewRequest,
  CartRentalOverviewResult,
  ResponseError,
  RequiredError,
  MoneyAmountWithRequiredTaxCurrencyEnum,
  RentContractTerm,
  RentContractTermDurationEnum,
  MoneyAmountCurrencyEnum,
  CartProductRentalOverview,
  SellerProductReference,
} from "seller-api-client";
