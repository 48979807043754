// eslint-disable-next-line import/no-cycle
import { languageMap } from "../i18n-provider";
import { DEFAULT_LOCALE } from "./locale.constants";

export function getProcessedLocale(locale: string): string {
  try {
    const regex = /^([a-z]{2})_?([a-z]{2})?$/;
    if (regex === null) {
      return DEFAULT_LOCALE;
    }

    const match = locale.toLowerCase().match(regex);
    if (match === null) {
      return DEFAULT_LOCALE;
    }

    const processedLocale = match[1];
    if (processedLocale === null) {
      return DEFAULT_LOCALE;
    }

    const acceptedLocales = Object.keys(languageMap);
    if (acceptedLocales.includes(processedLocale)) {
      return processedLocale;
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return DEFAULT_LOCALE;
}
