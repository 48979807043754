/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * @export
 */
export const MoneyAmountWithOptionalGrossCurrencyEnum = {
    Eur: 'EUR'
};
/**
 * Check if a given object implements the MoneyAmountWithOptionalGross interface.
 */
export function instanceOfMoneyAmountWithOptionalGross(value) {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "net" in value;
    return isInstance;
}
export function MoneyAmountWithOptionalGrossFromJSON(json) {
    return MoneyAmountWithOptionalGrossFromJSONTyped(json, false);
}
export function MoneyAmountWithOptionalGrossFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': json['currency'],
        'gross': !exists(json, 'gross') ? undefined : json['gross'],
        'net': json['net'],
    };
}
export function MoneyAmountWithOptionalGrossToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currency': value.currency,
        'gross': value.gross,
        'net': value.net,
    };
}
