import { useMemo, useState, useEffect, useRef } from "preact/hooks";
import { useSelector } from "react-redux";

import { CartRentalOverviewResult, RentContractTermDurationEnum } from "$/api";
import { getThemeConfig } from "$/slices/config-slice";
import { useAggregatedPrices } from "../../hooks/use-aggregated-prices";
import { useCartHasRentalTerms } from "../use-cart-has-rental-terms";
import { useTenureToggles } from "../use-tenure-toggles";
import { Container } from "./tenure-overview.styles";
import { TenurePrices } from "./tenure-prices/tenure-prices";
import { TenureToggleGroup } from "./tenure-toggle-group/tenure-toggle-group";

type TenureOverviewProps = {
  rentalOverview?: CartRentalOverviewResult;
};

export function TenureOverview({ rentalOverview }: TenureOverviewProps) {
  const cartHasRentalTerms = useCartHasRentalTerms(rentalOverview);
  const aggregatedPrices = useAggregatedPrices(rentalOverview);
  const themeConfig = useSelector(getThemeConfig);

  const availableRentDurations = useMemo(
    () =>
      aggregatedPrices
        ? (Object.keys(aggregatedPrices).map(
            Number
          ) as RentContractTermDurationEnum[])
        : [],
    [aggregatedPrices]
  );

  const [selectedTenure, setSelectedTenure] = useTenureToggles(
    availableRentDurations
  );

  const [isWide, setIsWide] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setIsWide(containerRef.current.offsetWidth >= 348);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <TenureToggleGroup
        availableRentDurations={availableRentDurations}
        selectedTenure={selectedTenure}
        setSelectedTenure={setSelectedTenure}
        isWide={isWide}
      />
      <TenurePrices
        aggregatedPrices={aggregatedPrices}
        availableRentDurations={availableRentDurations}
        cartHasRentalTerms={cartHasRentalTerms}
        selectedTenure={selectedTenure}
        themeConfig={themeConfig}
      />
    </Container>
  );
}
