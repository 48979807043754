import styled, { css } from "styled-components";

import { setTypographyImportant } from "$/ui/styles/mixins";
import { colors, spacing } from "$/ui/styles/tokens/tokens";

export const Container = styled.div`
  display: flex !important;
  width: 100% !important;
  gap: ${spacing.sp008} !important;
  flex-direction: row !important;
  justify-content: space-between !important;

  & > * {
    flex: 1 !important;
  }
`;

export const TenureToggle = styled.button<{
  isSelected?: boolean;
  primaryColor?: string;
}>`
  ${setTypographyImportant("body1")};
  display: inline-block !important;
  min-width: ${spacing.sp040} !important;
  background-color: transparent;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;

  & > span {
    display: inline-block !important;
    border-bottom: ${({ isSelected, primaryColor }) =>
      isSelected
        ? css`
            ${setTypographyImportant("body1Emphasis")};
            border-bottom: ${spacing.sp001} solid
              ${primaryColor ?? colors.topiBlue50} !important;
            color: ${primaryColor ?? colors.topiBlue50} !important;
          `
        : css`
            border-bottom: none !important;
          `};
  }
`;
