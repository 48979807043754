import register from "preact-custom-element";
import { loadFont } from "./fonts/font-loader";
import TopiElements from "./topi-elements";
import CartRentalSummaryLabel from "./widgets/cart-rental-summary-label/cart-rental-summary-label";
import CheckoutButton from "./widgets/checkout-button/checkout-button";
import LearnMoreInfo from "./widgets/learn-more-info/learn-more-info";
import ProductRentalSummaryLabel from "./widgets/product-rental-summary-label/product-rental-summary-label";
import RentalOverview from "./widgets/rental-overview/rental-overview";

loadFont();

// Note: all attributes passed into custom elements must be lowercase
register(CheckoutButton, "x-topi-checkout-button", [
  "onbuttonclick",
  "checkout-mode",
]);

register(RentalOverview, "x-topi-rental-overview", ["onbuttonclick"]);

register(CartRentalSummaryLabel, "x-topi-cart-rental-summary-label", [
  "onbuttonclick",
  "reference",
  "mode",
]);

register(ProductRentalSummaryLabel, "x-topi-product-rental-summary-label", [
  "onbuttonclick",
]);

register(LearnMoreInfo, "x-topi-learn-more-info", ["mode"]);

declare global {
  interface Window {
    TopiElements: typeof TopiElements;
  }
}

window.TopiElements = TopiElements;
