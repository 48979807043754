import { Text } from "preact-i18n";

import Modal from "../../../common/modal/modal";
import { Mode } from "../../../common/models";
import LearnMoreInfo from "../../learn-more-info/learn-more-info";
import useCleanUpNestedFonts from "$/use-cleanup-nested-fonts";

interface LearnMoreModalProps {
  onClose: () => void;
  mode?: Mode;
}

export function LearnMoreModal(props: LearnMoreModalProps) {
  // Fix duplicated font nodes when using google translate
  useCleanUpNestedFonts();

  const { onClose, mode } = props;

  return (
    <Modal
      isDismissible
      onClose={onClose}
      footerButtonAction={onClose}
      footerButtonText={<Text id="learnMoreCard.agreeButton" />}
    >
      <LearnMoreInfo mode={mode} />
    </Modal>
  );
}
