import { CartRentalOverviewResult, SellerProductReference } from "../../api";
import { Slice, TopiEvent } from "../../common/models";

type HandleEventDispatchingInput = {
  slice: Slice;
  payload: CartRentalOverviewResult;
};

const dispatchEvent = (
  eventType: TopiEvent,
  detail: SellerProductReference[]
) => {
  document.dispatchEvent(new CustomEvent(eventType, { detail }));
};

const handleCartCannotCheckout = (payload: CartRentalOverviewResult) => {
  const { canCheckout, productPrices } = payload;

  if (canCheckout) {
    return;
  }

  const itemsInCartThatCannotCheckout: SellerProductReference[] = productPrices
    .filter((item) => item.canCheckout === false)
    .map((item) => item.sellerProductReference);

  dispatchEvent("cartCannotCheckout", itemsInCartThatCannotCheckout);
};

export const handleEventDispatching = ({
  slice,
  payload,
}: HandleEventDispatchingInput) => {
  if (slice === "cart") {
    handleCartCannotCheckout(payload);
  }
};
