/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { AddCustomProductRequestBodyToJSON, CalculatePriceSummaryFromJSON, CalculatePricingRequestBodyToJSON, CartRentalOverviewRequestBodyToJSON, CartRentalOverviewResultFromJSON, CategorisationNodeFromJSON, CheckSupportedRequestBodyToJSON, ImportCatalogRequestBodyToJSON, ImportResultFromJSON, ListRecommendedRentalPricesRequestBodyToJSON, OfferParsingResultFromJSON, ParseOfferTextRequestBodyToJSON, ProductDetailsFromJSON, ProductPaginationResultFromJSON, ProductRentalPricingDetailsFromJSON, ProductsSummaryFromJSON, SearchProductResultFromJSON, WidgetsCartRentalOverviewRequestBodyToJSON, } from '../models';
/**
 *
 */
export class CatalogApi extends runtime.BaseAPI {
    /**
     * Add a single custom product to the catalog
     * Add a custom product
     */
    catalogAddCustomProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.addCustomProductRequestBody === null || requestParameters.addCustomProductRequestBody === undefined) {
                throw new runtime.RequiredError('addCustomProductRequestBody', 'Required parameter requestParameters.addCustomProductRequestBody was null or undefined when calling catalogAddCustomProduct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:edit"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/custom_product`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: AddCustomProductRequestBodyToJSON(requestParameters.addCustomProductRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
        });
    }
    /**
     * Add a single custom product to the catalog
     * Add a custom product
     */
    catalogAddCustomProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogAddCustomProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ## <span style=\"color:#cc3333\">*Deprecated*</span>  Calculate pricing endpoint is being replaced by two other endpoints: - listRecommendedRentalPrices - cartRentalOverview  Please make sure to migrate to one of the above endpoints based on your use case.   ### Description  calculate the price for a product
     * Calculate pricing for a single product
     */
    catalogCalculatePricingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.calculatePricingRequestBody === null || requestParameters.calculatePricingRequestBody === undefined) {
                throw new runtime.RequiredError('calculatePricingRequestBody', 'Required parameter requestParameters.calculatePricingRequestBody was null or undefined when calling catalogCalculatePricing.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/pricing`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CalculatePricingRequestBodyToJSON(requestParameters.calculatePricingRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CalculatePriceSummaryFromJSON(jsonValue));
        });
    }
    /**
     * ## <span style=\"color:#cc3333\">*Deprecated*</span>  Calculate pricing endpoint is being replaced by two other endpoints: - listRecommendedRentalPrices - cartRentalOverview  Please make sure to migrate to one of the above endpoints based on your use case.   ### Description  calculate the price for a product
     * Calculate pricing for a single product
     */
    catalogCalculatePricing(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogCalculatePricingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get rental overview for products
     */
    catalogCartRentalOverviewRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.cartRentalOverviewRequestBody === null || requestParameters.cartRentalOverviewRequestBody === undefined) {
                throw new runtime.RequiredError('cartRentalOverviewRequestBody', 'Required parameter requestParameters.cartRentalOverviewRequestBody was null or undefined when calling catalogCartRentalOverview.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/carts/rental-overview`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CartRentalOverviewRequestBodyToJSON(requestParameters.cartRentalOverviewRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CartRentalOverviewResultFromJSON(jsonValue));
        });
    }
    /**
     * Get rental overview for products
     */
    catalogCartRentalOverview(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogCartRentalOverviewRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Checks whether the products with the given references are supported or not.  ### When using in the Sandbox  Returns the requested products with their available contract terms, depending on the combination of the supplied Source and Reference values:  - To get a product with `PayNow` contract terms : The supplied `source` should be `SAP` with `reference` specified as `1234-5678`. - To get a product with `Rent` contract terms : There are two ways to get this value either when the supplied `source` is `SAP` and the `reference` is `1111-1111` or with `source` set to `B6` and `reference` specified as `2222-2222`. - To get a product that is not topi supported: Specify the `source` as `SAP` and `reference` should be `8765-4321`.  In other cases this method will return an empty array.  This method has been defined as POST because the size of the input values could grow fast.
     * Check if products are supported
     */
    catalogCheckSupportedRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.checkSupportedRequestBody === null || requestParameters.checkSupportedRequestBody === undefined) {
                throw new runtime.RequiredError('checkSupportedRequestBody', 'Required parameter requestParameters.checkSupportedRequestBody was null or undefined when calling catalogCheckSupported.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/check-supported`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CheckSupportedRequestBodyToJSON(requestParameters.checkSupportedRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProductsSummaryFromJSON(jsonValue));
        });
    }
    /**
     * Checks whether the products with the given references are supported or not.  ### When using in the Sandbox  Returns the requested products with their available contract terms, depending on the combination of the supplied Source and Reference values:  - To get a product with `PayNow` contract terms : The supplied `source` should be `SAP` with `reference` specified as `1234-5678`. - To get a product with `Rent` contract terms : There are two ways to get this value either when the supplied `source` is `SAP` and the `reference` is `1111-1111` or with `source` set to `B6` and `reference` specified as `2222-2222`. - To get a product that is not topi supported: Specify the `source` as `SAP` and `reference` should be `8765-4321`.  In other cases this method will return an empty array.  This method has been defined as POST because the size of the input values could grow fast.
     * Check if products are supported
     */
    catalogCheckSupported(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogCheckSupportedRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Edit an out of catalog product
     * edit an out of catalog product
     */
    catalogEditCustomProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling catalogEditCustomProduct.');
            }
            if (requestParameters.addCustomProductRequestBody === null || requestParameters.addCustomProductRequestBody === undefined) {
                throw new runtime.RequiredError('addCustomProductRequestBody', 'Required parameter requestParameters.addCustomProductRequestBody was null or undefined when calling catalogEditCustomProduct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:edit"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/custom_product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: AddCustomProductRequestBodyToJSON(requestParameters.addCustomProductRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProductDetailsFromJSON(jsonValue));
        });
    }
    /**
     * Edit an out of catalog product
     * edit an out of catalog product
     */
    catalogEditCustomProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogEditCustomProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * get categorisation information for populating frontend dropdowns
     * Retrieve product categorization information
     */
    catalogGetCategorisationInformationRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/categorisation_information`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategorisationNodeFromJSON));
        });
    }
    /**
     * get categorisation information for populating frontend dropdowns
     * Retrieve product categorization information
     */
    catalogGetCategorisationInformation(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogGetCategorisationInformationRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * get product details by ID
     * Get product details by ID
     */
    catalogGetProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling catalogGetProduct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProductDetailsFromJSON(jsonValue));
        });
    }
    /**
     * get product details by ID
     * Get product details by ID
     */
    catalogGetProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogGetProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Add a batch of products to the catalog.  ### When using in the Sandbox  Use the following scenarios to get different fixtures:  - Status `\"completed\"`: `Send a catalog with less than 10 products` - Status `\"created\"`: `Send a catalog with 10 products or more` - Status `\"failed\"`: `Send a product inside the catalog with the \"source\" or the \"reference\" in the \"seller_product_reference\" as empty string`
     * Import products into the catalog
     */
    catalogImportCatalogRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.importCatalogRequestBody === null || requestParameters.importCatalogRequestBody === undefined) {
                throw new runtime.RequiredError('importCatalogRequestBody', 'Required parameter requestParameters.importCatalogRequestBody was null or undefined when calling catalogImportCatalog.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:edit"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/import`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ImportCatalogRequestBodyToJSON(requestParameters.importCatalogRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ImportResultFromJSON(jsonValue));
        });
    }
    /**
     * Add a batch of products to the catalog.  ### When using in the Sandbox  Use the following scenarios to get different fixtures:  - Status `\"completed\"`: `Send a catalog with less than 10 products` - Status `\"created\"`: `Send a catalog with 10 products or more` - Status `\"failed\"`: `Send a product inside the catalog with the \"source\" or the \"reference\" in the \"seller_product_reference\" as empty string`
     * Import products into the catalog
     */
    catalogImportCatalog(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogImportCatalogRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Given a list of products, retrieve information about the recommended monthly rental price and term, if applicable, for each of the requested products.
     * List recommended rental prices for products
     */
    catalogListRecommendedRentalPricesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.listRecommendedRentalPricesRequestBody === null || requestParameters.listRecommendedRentalPricesRequestBody === undefined) {
                throw new runtime.RequiredError('listRecommendedRentalPricesRequestBody', 'Required parameter requestParameters.listRecommendedRentalPricesRequestBody was null or undefined when calling catalogListRecommendedRentalPrices.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/list-recommended-rental-prices`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ListRecommendedRentalPricesRequestBodyToJSON(requestParameters.listRecommendedRentalPricesRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductRentalPricingDetailsFromJSON));
        });
    }
    /**
     * Given a list of products, retrieve information about the recommended monthly rental price and term, if applicable, for each of the requested products.
     * List recommended rental prices for products
     */
    catalogListRecommendedRentalPrices(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogListRecommendedRentalPricesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Parse offer content provided as text
     * parseOfferText catalog
     */
    catalogParseOfferTextRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.parseOfferTextRequestBody === null || requestParameters.parseOfferTextRequestBody === undefined) {
                throw new runtime.RequiredError('parseOfferTextRequestBody', 'Required parameter requestParameters.parseOfferTextRequestBody was null or undefined when calling catalogParseOfferText.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:edit"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/parse-offer`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ParseOfferTextRequestBodyToJSON(requestParameters.parseOfferTextRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OfferParsingResultFromJSON(jsonValue));
        });
    }
    /**
     * Parse offer content provided as text
     * parseOfferText catalog
     */
    catalogParseOfferText(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogParseOfferTextRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * delete a single custom product from the catalog
     * Remove custom product
     */
    catalogRemoveCustomProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling catalogRemoveCustomProduct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:edit"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/custom_product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * delete a single custom product from the catalog
     * Remove custom product
     */
    catalogRemoveCustomProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.catalogRemoveCustomProductRaw(requestParameters, initOverrides);
        });
    }
    /**
     * get products that match a search term
     * Search products by term
     */
    catalogSearchProductsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.term !== undefined) {
                queryParameters['term'] = requestParameters.term;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/products`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchProductResultFromJSON));
        });
    }
    /**
     * get products that match a search term
     * Search products by term
     */
    catalogSearchProducts(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogSearchProductsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * get paginated products that match a search term
     * Paginated product search by term
     */
    catalogSearchProductsPaginatedRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.term !== undefined) {
                queryParameters['term'] = requestParameters.term;
            }
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                // oauth required
                headerParameters["Authorization"] = yield this.configuration.accessToken("OAuth2Seller_header_Authorization", ["seller-catalog:read"]);
            }
            const response = yield this.request({
                path: `/v1/catalog/products_paginated`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProductPaginationResultFromJSON(jsonValue));
        });
    }
    /**
     * get paginated products that match a search term
     * Paginated product search by term
     */
    catalogSearchProductsPaginated(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogSearchProductsPaginatedRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * widgetsCartRentalOverview catalog
     */
    catalogWidgetsCartRentalOverviewRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.widgetsCartRentalOverviewRequestBody === null || requestParameters.widgetsCartRentalOverviewRequestBody === undefined) {
                throw new runtime.RequiredError('widgetsCartRentalOverviewRequestBody', 'Required parameter requestParameters.widgetsCartRentalOverviewRequestBody was null or undefined when calling catalogWidgetsCartRentalOverview.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/v1/catalog/widgets/carts/rental-overview`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: WidgetsCartRentalOverviewRequestBodyToJSON(requestParameters.widgetsCartRentalOverviewRequestBody),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CartRentalOverviewResultFromJSON(jsonValue));
        });
    }
    /**
     * widgetsCartRentalOverview catalog
     */
    catalogWidgetsCartRentalOverview(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.catalogWidgetsCartRentalOverviewRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
