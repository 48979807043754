/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MoneyAmountWithOptionalTaxFromJSON, MoneyAmountWithOptionalTaxToJSON, } from './MoneyAmountWithOptionalTax';
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * Check if a given object implements the PricingRequest interface.
 */
export function instanceOfPricingRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "sellerProductReference" in value;
    return isInstance;
}
export function PricingRequestFromJSON(json) {
    return PricingRequestFromJSONTyped(json, false);
}
export function PricingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'baseRentalPrice': !exists(json, 'base_rental_price') ? undefined : MoneyAmountWithOptionalTaxFromJSON(json['base_rental_price']),
        'price': !exists(json, 'price') ? undefined : MoneyAmountWithOptionalTaxFromJSON(json['price']),
        'sellerProductReference': SellerProductReferenceFromJSON(json['seller_product_reference']),
    };
}
export function PricingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'base_rental_price': MoneyAmountWithOptionalTaxToJSON(value.baseRentalPrice),
        'price': MoneyAmountWithOptionalTaxToJSON(value.price),
        'seller_product_reference': SellerProductReferenceToJSON(value.sellerProductReference),
    };
}
