/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OfferParsingUnproccesedItemFromJSON, OfferParsingUnproccesedItemToJSON, } from './OfferParsingUnproccesedItem';
import { OfferParsinglineItemFromJSON, OfferParsinglineItemToJSON, } from './OfferParsinglineItem';
/**
 * Check if a given object implements the OfferParsingResult interface.
 */
export function instanceOfOfferParsingResult(value) {
    let isInstance = true;
    return isInstance;
}
export function OfferParsingResultFromJSON(json) {
    return OfferParsingResultFromJSONTyped(json, false);
}
export function OfferParsingResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lineItems': !exists(json, 'line_items') ? undefined : (json['line_items'].map(OfferParsinglineItemFromJSON)),
        'offerReference': !exists(json, 'offer_reference') ? undefined : json['offer_reference'],
        'unprocessedItems': !exists(json, 'unprocessed_items') ? undefined : (json['unprocessed_items'].map(OfferParsingUnproccesedItemFromJSON)),
    };
}
export function OfferParsingResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'line_items': value.lineItems === undefined ? undefined : (value.lineItems.map(OfferParsinglineItemToJSON)),
        'offer_reference': value.offerReference,
        'unprocessed_items': value.unprocessedItems === undefined ? undefined : (value.unprocessedItems.map(OfferParsingUnproccesedItemToJSON)),
    };
}
