import { CartRentalOverviewResult } from "../../api";

export function useCartHasRentalTerms(
  rentalOverview?: CartRentalOverviewResult
): boolean {
  if (!rentalOverview) {
    return false;
  }

  const hasRentalTerms = rentalOverview.productPrices?.every(
    (pp) => pp?.hasRentalTerms
  );

  return !!hasRentalTerms;
}
