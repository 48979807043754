import styled from "styled-components";

import { sizes } from "$/ui/styles/tokens/tokens";

import TrustpilotLogoSvg from "../../assets/svgs/trustpilot-logo.svg";
import TrustpilotRatingSvg from "../../assets/svgs/trustpilot-rating.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${sizes.sz012};
`;

export const TrustpilotLogo = styled(TrustpilotLogoSvg)``;

export const TrustpilotRating = styled(TrustpilotRatingSvg)``;
