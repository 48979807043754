export enum ApiResourceStatus {
  Stopped,
  Pending,
  Found,
  NotFound,
  ServerError,
  ClientError,
}

export interface ApiError {
  code: number;
  message: string;
  errorName?: string;
}

export const mapApiErrorToApiResourceStatus = (
  apiError?: ApiError
): ApiResourceStatus => {
  if (!apiError?.code) {
    return ApiResourceStatus.ClientError;
  }
  if (apiError.code >= 500) {
    return ApiResourceStatus.ServerError;
  }
  if (apiError.code === 404) {
    return ApiResourceStatus.NotFound;
  }
  return ApiResourceStatus.ClientError;
};

export type Slice = "cart" | "pdp";
export type Mode = "cart" | "product";

export type TopiEvent = "cartCannotCheckout";
