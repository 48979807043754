import styled from "styled-components";

import LogoSvg from "$/ui/assets/svgs/brand/topi-logo-on-black.svg";
import { setTypographyImportant } from "$/ui/styles/mixins";
import {
  borderRadius,
  colors,
  sizes,
  spacing,
} from "$/ui/styles/tokens/tokens";
import { pxScaled } from "$/ui/utils/px-scaled";

const CHECKOUT_BUTTON_MIN_WIDTH = 192;

interface ContainerProps {
  nonFullWidth?: boolean;
  hasChildren?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${setTypographyImportant("headline4")};
  color: ${colors.white} !important;

  box-sizing: border-box !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: ${colors.grey90} !important;
  width: ${(props) => (props.nonFullWidth ? undefined : "100% !important")};
  padding: ${({ hasChildren }) =>
    hasChildren
      ? `${spacing.sp012} ${spacing.sp024} !important`
      : `${spacing.sp004} ${spacing.sp008} !important`};
  border: none !important;
  border-radius: ${borderRadius.s} !important;
  min-width: ${({ nonFullWidth }) =>
    nonFullWidth ? "" : `${pxScaled(CHECKOUT_BUTTON_MIN_WIDTH)} !important`};
  cursor: default !important;
`;

export const Logo = styled(LogoSvg)`
  width: ${sizes.sz032} !important;
  height: auto !important;
  margin-inline-start: ${({ hasChildren }) =>
    hasChildren ? `${spacing.sp004} !important` : undefined};
`;
