/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculatePriceSummaryFromJSON, CalculatePriceSummaryToJSON, } from './CalculatePriceSummary';
import { SearchProductResultFromJSON, SearchProductResultToJSON, } from './SearchProductResult';
/**
 * Check if a given object implements the OfferParsinglineItem interface.
 */
export function instanceOfOfferParsinglineItem(value) {
    let isInstance = true;
    isInstance = isInstance && "calculatedPriceSummary" in value;
    isInstance = isInstance && "inputQuantity" in value;
    isInstance = isInstance && "product" in value;
    return isInstance;
}
export function OfferParsinglineItemFromJSON(json) {
    return OfferParsinglineItemFromJSONTyped(json, false);
}
export function OfferParsinglineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'calculatedPriceSummary': CalculatePriceSummaryFromJSON(json['calculated_price_summary']),
        'inputQuantity': json['input_quantity'],
        'product': SearchProductResultFromJSON(json['product']),
    };
}
export function OfferParsinglineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'calculated_price_summary': CalculatePriceSummaryToJSON(value.calculatedPriceSummary),
        'input_quantity': value.inputQuantity,
        'product': SearchProductResultToJSON(value.product),
    };
}
