import { Text } from "preact-i18n";
import { useSelector } from "react-redux";

import { MoneyAmountCurrencyEnum } from "../../api";
import { getLocaleConfig } from "../../slices/config-slice";

function getDecimalsForCurrency(currency: MoneyAmountCurrencyEnum): number {
  const TWO_DECIMAL_PLACES = 2;

  if (currency === MoneyAmountCurrencyEnum.Eur) {
    return TWO_DECIMAL_PLACES;
  }
  throw new Error(`Currency ${currency} is not supported for display`);
}

function basisPointsToDollars(basisPoints: number): number {
  return basisPoints / 100.0;
}

export function useMoneyAmount(
  amount?: number,
  currency?: MoneyAmountCurrencyEnum
): JSX.Element | undefined {
  const localeConfig = useSelector(getLocaleConfig);

  if (amount === undefined || !currency) {
    return undefined;
  }

  try {
    const valueForUk = basisPointsToDollars(amount).toFixed(
      getDecimalsForCurrency(currency)
    );
    const valueForEurope = valueForUk.replace(".", ",");
    const symbol = currency === MoneyAmountCurrencyEnum.Eur ? "€" : "";

    return (
      <Text
        id="bareMoneyAmount"
        fields={{
          moneyAmount: localeConfig === "en" ? valueForUk : valueForEurope,
          currency: symbol,
        }}
      />
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return undefined;
  }
}
