import styled from "styled-components";

import { Icon as DefaultIcon, IconProps } from "$/ui/components/icon/icon";
import { Logo } from "$/ui/components/logo/logo";
import {
  setTopLevelSharedCssVariables,
  setTypographyImportant,
} from "$/ui/styles/mixins";
import {
  borderWidths,
  colors,
  sizes,
  spacing,
} from "$/ui/styles/tokens/tokens";

export type Color = "grey" | "green" | "blue";

const iconColor = (color: Color) => {
  switch (color) {
    case "grey": {
      return colors.grey90;
    }
    case "blue": {
      return colors.topiBlue50;
    }
    case "green": {
      return colors.green70;
    }
    default: {
      return colors.topiBlue50;
    }
  }
};

export const Container = styled.div<{ primaryColor?: string }>`
  ${setTopLevelSharedCssVariables()};

  display: flex !important;
  flex-direction: column !important;
  gap: ${spacing.sp032} !important;
`;

export const Header = styled.div`
  ${setTypographyImportant("headline3")};
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  gap: ${spacing.sp008} !important;
`;

export const Subheader = styled.p`
  ${setTypographyImportant("body1")};
  margin: 0 !important;
`;

export const InnerContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  gap: ${spacing.sp012} !important;
`;

export const SectionHeader = styled.div`
  ${setTypographyImportant("body1Emphasis")}
  border-bottom: ${borderWidths.sm} solid ${colors.grey10} !important;
  padding-bottom: ${spacing.sp012} !important;
  font-size: ${sizes.sz016} !important;
`;

export const CollapsableContainerHeader = styled.div`
  ${setTypographyImportant("body1Emphasis")}
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding-bottom: ${spacing.sp008} !important;
`;

export const CollapsableContainerFooter = styled.div`
  ${setTypographyImportant("body1")}
  padding-bottom: ${spacing.sp012} !important;
  display: flex !important;
  flex-direction: column !important;

  strong {
    font-weight: 500 !important;
  }
`;

export const CollapsableContainerItem = styled.div`
  border-bottom: ${borderWidths.sm} solid ${colors.grey10} !important;
  display: flex !important;
  flex-direction: column !important;
  gap: ${spacing.sp008} !important;
`;

export const Footer = styled.div`
  ${setTypographyImportant("body1")};
  display: flex !important;
  gap: ${sizes.sz012} !important;
  flex-wrap: wrap !important;
`;

export const RatingOverview = styled.div`
  ${setTypographyImportant("body1Emphasis")};
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: ${sizes.sz012} !important;
`;

export const TopiLogo = styled(Logo)`
  width: ${sizes.sz040} !important;
`;

export const Icon = styled(DefaultIcon)<
  IconProps & {
    color: Color;
    onTheLeft?: boolean;
  }
>`
  min-width: ${sizes.sz016} !important;
  height: ${sizes.sz016} !important;
  color: ${({ color }) => iconColor(color)} !important;
  margin-left: ${({ onTheLeft }) =>
    onTheLeft ? "auto !important;" : "none !important;"};
`;

export const Link = styled.a`
  ${setTypographyImportant("body1Emphasis")};
  color: ${colors.topiBlue50} !important;
`;
