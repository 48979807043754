import styled from "styled-components";

import { borderRadius, colors, spacing } from "$/ui/styles/tokens/tokens";

export const Container = styled.div`
  display: flex !important;
  flex-direction: column !important;
  gap: ${spacing.sp016} !important;

  & > * {
    margin: 0 !important;
  }

  align-items: center !important;
  padding: ${spacing.sp016} !important;
  border: 1px solid ${colors.grey20} !important;
  border-radius: ${borderRadius.l} !important;
  min-height: 198px !important;
  box-sizing: border-box !important;
  flex: 1 1 auto;
`;
