/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SummaryInfoFromJSON, SummaryInfoToJSON, } from './SummaryInfo';
/**
 * @export
 */
export const ImportResultStatusEnum = {
    Created: 'created',
    Started: 'started',
    Completed: 'completed',
    Failed: 'failed'
};
/**
 * Check if a given object implements the ImportResult interface.
 */
export function instanceOfImportResult(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function ImportResultFromJSON(json) {
    return ImportResultFromJSONTyped(json, false);
}
export function ImportResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'status': json['status'],
        'summary': !exists(json, 'summary') ? undefined : SummaryInfoFromJSON(json['summary']),
    };
}
export function ImportResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'status': value.status,
        'summary': SummaryInfoToJSON(value.summary),
    };
}
