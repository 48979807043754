/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractTermsSummaryFromJSON, ContractTermsSummaryToJSON, } from './ContractTermsSummary';
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * Check if a given object implements the ProductSummary interface.
 */
export function instanceOfProductSummary(value) {
    let isInstance = true;
    isInstance = isInstance && "availableContractTerms" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isSupported" in value;
    isInstance = isInstance && "sellerProductReference" in value;
    return isInstance;
}
export function ProductSummaryFromJSON(json) {
    return ProductSummaryFromJSONTyped(json, false);
}
export function ProductSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableContractTerms': ContractTermsSummaryFromJSON(json['available_contract_terms']),
        'id': json['id'],
        'isSupported': json['is_supported'],
        'sellerProductReference': SellerProductReferenceFromJSON(json['seller_product_reference']),
    };
}
export function ProductSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'available_contract_terms': ContractTermsSummaryToJSON(value.availableContractTerms),
        'id': value.id,
        'is_supported': value.isSupported,
        'seller_product_reference': SellerProductReferenceToJSON(value.sellerProductReference),
    };
}
