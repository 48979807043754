/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CategorisationIdentifiersFromJSON, CategorisationIdentifiersToJSON, } from './CategorisationIdentifiers';
import { MoneyAmountWithOptionalGrossFromJSON, MoneyAmountWithOptionalGrossToJSON, } from './MoneyAmountWithOptionalGross';
import { ProductStandardIdentifierFromJSON, ProductStandardIdentifierToJSON, } from './ProductStandardIdentifier';
/**
 * Check if a given object implements the CustomProduct interface.
 */
export function instanceOfCustomProduct(value) {
    let isInstance = true;
    isInstance = isInstance && "categorisationIdentifiers" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "manufacturer" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function CustomProductFromJSON(json) {
    return CustomProductFromJSONTyped(json, false);
}
export function CustomProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'categorisationIdentifiers': CategorisationIdentifiersFromJSON(json['CategorisationIdentifiers']),
        'baseRentalPrice': !exists(json, 'base_rental_price') ? undefined : MoneyAmountWithOptionalGrossFromJSON(json['base_rental_price']),
        'description': json['description'],
        'manufacturer': json['manufacturer'],
        'price': MoneyAmountWithOptionalGrossFromJSON(json['price']),
        'productStandardIdentifiers': !exists(json, 'product_standard_identifiers') ? undefined : (json['product_standard_identifiers'].map(ProductStandardIdentifierFromJSON)),
        'reference': json['reference'],
        'title': json['title'],
    };
}
export function CustomProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'CategorisationIdentifiers': CategorisationIdentifiersToJSON(value.categorisationIdentifiers),
        'base_rental_price': MoneyAmountWithOptionalGrossToJSON(value.baseRentalPrice),
        'description': value.description,
        'manufacturer': value.manufacturer,
        'price': MoneyAmountWithOptionalGrossToJSON(value.price),
        'product_standard_identifiers': value.productStandardIdentifiers === undefined ? undefined : (value.productStandardIdentifiers.map(ProductStandardIdentifierToJSON)),
        'reference': value.reference,
        'title': value.title,
    };
}
