/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { RentContractTermFromJSON, RentContractTermToJSON, } from './RentContractTerm';
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * @export
 */
export const ProductRentalPricingDetailsCurrencyEnum = {
    Eur: 'EUR'
};
/**
 * Check if a given object implements the ProductRentalPricingDetails interface.
 */
export function instanceOfProductRentalPricingDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "hasRentalTerms" in value;
    isInstance = isInstance && "sellerProductReference" in value;
    return isInstance;
}
export function ProductRentalPricingDetailsFromJSON(json) {
    return ProductRentalPricingDetailsFromJSONTyped(json, false);
}
export function ProductRentalPricingDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'hasRentalTerms': json['has_rental_terms'],
        'monthlyRentalAmount': !exists(json, 'monthly_rental_amount') ? undefined : json['monthly_rental_amount'],
        'monthlyRentalTerms': !exists(json, 'monthly_rental_terms') ? undefined : RentContractTermFromJSON(json['monthly_rental_terms']),
        'sellerProductReference': SellerProductReferenceFromJSON(json['seller_product_reference']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}
export function ProductRentalPricingDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currency': value.currency,
        'has_rental_terms': value.hasRentalTerms,
        'monthly_rental_amount': value.monthlyRentalAmount,
        'monthly_rental_terms': RentContractTermToJSON(value.monthlyRentalTerms),
        'seller_product_reference': SellerProductReferenceToJSON(value.sellerProductReference),
        'summary': value.summary,
    };
}
