import { IntlProvider } from "preact-i18n";

import deStrings from "./translations/de/translation.json";
import enStrings from "./translations/en/translation.json";
// eslint-disable-next-line import/no-cycle
import { DEFAULT_LOCALE, getProcessedLocale } from "./utils/locale";

interface I18nProviderProps {
  children: JSX.Element | JSX.Element[];
  locale: string;
}

interface LocaleData {
  // eslint-disable-next-line @typescript-eslint/ban-types
  definition: {};
  nativeName: string;
}

export const languageMap: { [key: string]: LocaleData } = {
  en: { definition: enStrings, nativeName: "English" },
  de: { definition: deStrings, nativeName: "Deutsch" },
};

export function getLang(locale: string) {
  try {
    const processedLocale = getProcessedLocale(locale);
    return languageMap[processedLocale] ?? languageMap[DEFAULT_LOCALE];
  } catch {
    return languageMap[DEFAULT_LOCALE];
  }
}

export default function I18nProvider({ children, locale }: I18nProviderProps) {
  const lang = getLang(locale);
  return <IntlProvider definition={lang.definition}>{children}</IntlProvider>;
}
