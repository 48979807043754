export const FontURL = "https://widgets.topi.eu/fonts/readex-pro.woff2";

export function loadFont() {
  const head = document.querySelectorAll("head")[0];
  const preloadFontLink = document.createElement("link");
  preloadFontLink.setAttribute("href", FontURL);
  preloadFontLink.setAttribute("rel", "preload");
  preloadFontLink.setAttribute("as", "font");
  preloadFontLink.setAttribute("crossorigin", "");
  head.append(preloadFontLink);

  const fontReadexPro = new FontFace(
    "Readex Pro",
    `url("${FontURL}") format("woff2-variations")`,
    { weight: "1 999" }
  );
  fontReadexPro.load().then(
    () => document.fonts.add(fontReadexPro),
    (error) => {
      // eslint-disable-next-line no-console
      console.error(`error loading Readex Pro font face`, error);
    }
  );
}
