import { Provider as ReduxProvider, useSelector } from "react-redux";

import { Text } from "preact-i18n";
import { useState } from "preact/hooks";
import { store } from "../../store";

import I18nProvider from "$/i18n/i18n-provider";
import {
  Body,
  Container,
  Icon,
  LinkButton,
  ListItem,
  RentalInfo,
  Title,
  UnorderedList,
} from "./rental-overview.styles";

import ErrorBoundary from "../../common/error-boundary/error-boundary";
import { getFetchedRentalOverviewForCart } from "../../slices/cart-slice";
import { getLocaleConfig } from "../../slices/config-slice";
import { LearnMoreModal } from "../common/learn-more-modal/learn-more-modal";
import { TenureOverview } from "./tenure-overview/tenure-overview";
import { useCartHasRentalTerms } from "./use-cart-has-rental-terms";
import useCleanUpNestedFonts from "$/use-cleanup-nested-fonts";

function RentalOverviewImpl() {
  const localeConfig = useSelector(getLocaleConfig);
  const rentalOverviewForCart = useSelector(getFetchedRentalOverviewForCart);
  const cartHasRentalTerms = useCartHasRentalTerms(rentalOverviewForCart);
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = (event: Event) => {
    event.preventDefault();
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  // Fix duplicated font nodes when using google translate
  useCleanUpNestedFonts();

  return cartHasRentalTerms ? (
    <I18nProvider locale={localeConfig}>
      <Container id="topi-rental-overview">
        <RentalInfo>
          <Title>
            <Text id="rentalOverview.advantagesOfRenting.title" />
          </Title>
          <Body>
            <UnorderedList>
              <ListItem>
                <Icon name="checkCircle" />
                <Text id="rentalOverview.advantagesOfRenting.0" />
              </ListItem>
              <ListItem>
                <Icon name="checkCircle" />
                <Text id="rentalOverview.advantagesOfRenting.1" />
              </ListItem>
              <ListItem>
                <Icon name="checkCircle" />
                <Text id="rentalOverview.advantagesOfRenting.2" />
              </ListItem>
            </UnorderedList>
          </Body>
          <LinkButton onClick={openModal} type="button">
            <Text id="learnMore" />
          </LinkButton>
        </RentalInfo>
        <TenureOverview rentalOverview={rentalOverviewForCart} />
      </Container>
      {isModalOpen && <LearnMoreModal onClose={closeModal} mode="cart" />}
    </I18nProvider>
  ) : undefined;
}

export default function RentalOverview() {
  return (
    <>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <RentalOverviewImpl />
        </ErrorBoundary>
      </ReduxProvider>
    </>
  );
}
