export const sanitizeColor = (
  color: string | undefined
): string | undefined => {
  const hexRegex = /^#([\da-f]{3}){1,2}$/i;
  if (hexRegex.test(color)) {
    return color;
  }

  const rgbRegex =
    /^rgba?\((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d),\s*(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d),\s*(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(,\s*(1|0|0?\.\d+))?\)$/;
  if (rgbRegex.test(color)) {
    return color;
  }

  const hslRegex = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g;
  if (hslRegex.test(color)) {
    return color;
  }

  return undefined;
};
