import { CartRentalOverviewResult } from "../../../api";
import { Mode } from "../../../common/models";

type TotalRentalAmount = number | undefined;

export function useProductModeRentalAmount(
  mode: Mode,
  rentalOverviewForCart: CartRentalOverviewResult,
  reference?: string
): TotalRentalAmount {
  if (!reference || mode === "cart" || !rentalOverviewForCart) {
    return undefined;
  }

  const productPrice = rentalOverviewForCart.productPrices?.find(
    (p) => p.sellerProductReference?.reference === reference
  );

  return productPrice?.totalRentalAmount;
}
