import styled from "styled-components";

import { pxScaled } from "$/ui/utils/px-scaled";

export const LogoSVG = styled.svg<{ size: "s" | "m" }>`
  width: var(--logo-width);
  height: var(--logo-height);

  ${({ size }) => {
    switch (size) {
      case "s": {
        return `
          --logo-width: ${pxScaled(42)};
          --logo-height: ${pxScaled(24)};
        `;
      }

      case "m": {
        return `
          --logo-width: ${pxScaled(56)};
          --logo-height: ${pxScaled(32)};
        `;
      }

      default: {
        return "";
      }
    }
  }}
`;
