import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { DEFAULT_LOCALE } from "$/i18n/utils/locale.constants";
// circular dependency may need fixing, but disabling linter works for now
// eslint-disable-next-line import/no-cycle
import { RootStateWidgets } from "../store";

export interface ThemeConfig {
  primaryColor: string;
}

// Define a type for the slice state
interface ConfigState {
  locale?: string;
  widgetId?: string;
  themeConfig?: ThemeConfig;
}

// Define the initial state using that type
const initialState: ConfigState = {
  locale: DEFAULT_LOCALE,
};

/* eslint-disable no-param-reassign */
export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setWidgetId: (state, action: PayloadAction<string>) => {
      state.widgetId = action.payload;
    },
    setThemeConfig: (state, action: PayloadAction<ThemeConfig>) => {
      state.themeConfig = action.payload;
    },
  },
});

export const { setLocale, setWidgetId, setThemeConfig } = configSlice.actions;

export default configSlice.reducer;

export const getLocaleConfig = (state: RootStateWidgets): string =>
  state.config.locale;

export const getWidgetIdConfig = (state: RootStateWidgets): string =>
  state.config.widgetId;

export const getThemeConfig = (state: RootStateWidgets): ThemeConfig =>
  state.config.themeConfig;
