import { createSlice } from "@reduxjs/toolkit";

// circular dependency may need fixing, but disabling linter works for now
// eslint-disable-next-line import/no-cycle
import { RootStateWidgets } from "../store";
import {
  ApiError,
  ApiResourceStatus,
  mapApiErrorToApiResourceStatus,
} from "../common/models";
import { CartRentalOverviewResult } from "../api";
import { CartItem } from "./catalog-models";
import { getRentalOverview } from "./common/rental-overview-thunk";
import { handleEventDispatching } from "./common/events-handler";

interface CartState {
  getRentalOverviewStatus: ApiResourceStatus;
  getRentalOverviewError?: ApiError;
  rentalOverviewForCart?: CartRentalOverviewResult;
  lastFetchTime?: number;
  lastInputPayload?: CartItem[];
}

const initialState: CartState = {
  getRentalOverviewStatus: ApiResourceStatus.Stopped,
  getRentalOverviewError: undefined,
  rentalOverviewForCart: undefined,
};

/* eslint-disable no-param-reassign */
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLastFetchTime: (state, action) => {
      state.lastFetchTime = action.payload;
    },
    setLastInputPayload: (state, action) => {
      state.lastInputPayload = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRentalOverview.pending, (state, { meta }) => {
      if (meta.arg.slice !== "cart") {
        return;
      }
      state.getRentalOverviewStatus = ApiResourceStatus.Pending;
      state.getRentalOverviewError = undefined;
    });
    builder.addCase(getRentalOverview.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.slice !== "cart") {
        return;
      }
      state.getRentalOverviewStatus = ApiResourceStatus.Found;
      state.rentalOverviewForCart = payload;
      state.getRentalOverviewError = undefined;

      state.lastFetchTime = Date.now();
      state.lastInputPayload = meta.arg.cartItems;

      handleEventDispatching({
        payload,
        slice: meta.arg.slice,
      });
    });
    builder.addCase(getRentalOverview.rejected, (state, { payload, meta }) => {
      if (meta.arg.slice !== "cart") {
        return;
      }
      const apiError = payload as ApiError;
      state.getRentalOverviewStatus = mapApiErrorToApiResourceStatus(apiError);
      state.getRentalOverviewError = apiError;
    });
  },
});

export const getFetchedRentalOverviewForCart = (
  state: RootStateWidgets
): CartRentalOverviewResult | undefined => state.cart.rentalOverviewForCart;

export default cartSlice.reducer;
