import styled from "styled-components";

import { Card } from "$/ui/components/card/card";
import {
  setTopLevelSharedCssVariables,
  setTypographyImportant,
} from "$/ui/styles/mixins";
import {
  blurRadius,
  borderRadius,
  colors,
  sizes,
  spacing,
  zIndices,
} from "$/ui/styles/tokens/tokens";
import { modalWidth } from "./modal-width-context";

export const ModalBackdrop = styled.div`
  ${setTopLevelSharedCssVariables()};

  position: fixed !important;
  z-index: ${zIndices.modal} !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  background: ${colors.alpha20} !important;
  padding-top: ${spacing.sp064} !important;
  padding-bottom: ${spacing.sp064} !important;
  min-height: auto !important;
  height: auto !important;
  overflow-y: auto !important;
  @supports (backdrop-filter: blur(${blurRadius.overlay})) {
    backdrop-filter: blur(${blurRadius.overlay}) !important;
  }
`;

export const ModalContent = styled(Card)<{ width: string }>`
  position: relative !important;
  box-sizing: border-box !important;
  padding: ${spacing.sp040} !important;
  width: ${({ width }) => modalWidth[width]?.widthM};
  margin: auto !important;
`;

export const CloseButton = styled.button`
  background: none !important;
  position: absolute !important;
  inset-block-start: ${spacing.sp024} !important;
  inset-inline-end: ${spacing.sp024} !important;
  border: none !important;
  padding: 0 !important;
  cursor: pointer !important;
  height: ${sizes.sz012} !important;
  width: ${sizes.sz012} !important;
`;

export const Footer = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 2rem !important;
`;

export const Button = styled.button`
  ${setTypographyImportant("body1Emphasis")};
  background-color: ${colors.topiBlue50} !important;
  border: none !important;
  padding: ${sizes.sz012} ${sizes.sz016} ${sizes.sz012} ${sizes.sz016} !important;
  border-radius: ${borderRadius.xxl} !important;
  font-size: ${sizes.sz012} !important;
  color: ${colors.white} !important;
  cursor: pointer !important;
  &:hover {
    background-color: ${colors.topiBlue60} !important;
  }
`;
