/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RentalOverviewRequestFromJSON, RentalOverviewRequestToJSON, } from './RentalOverviewRequest';
/**
 * Check if a given object implements the WidgetsCartRentalOverviewRequestBody interface.
 */
export function instanceOfWidgetsCartRentalOverviewRequestBody(value) {
    let isInstance = true;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "widgetId" in value;
    return isInstance;
}
export function WidgetsCartRentalOverviewRequestBodyFromJSON(json) {
    return WidgetsCartRentalOverviewRequestBodyFromJSONTyped(json, false);
}
export function WidgetsCartRentalOverviewRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'products': (json['products'].map(RentalOverviewRequestFromJSON)),
        'widgetId': json['widget_id'],
    };
}
export function WidgetsCartRentalOverviewRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'products': (value.products.map(RentalOverviewRequestToJSON)),
        'widget_id': value.widgetId,
    };
}
