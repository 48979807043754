import {
  CartRentalOverviewResult,
  MoneyAmountWithRequiredTaxCurrencyEnum,
  RentContractTerm,
  RentContractTermDurationEnum,
  CartProductRentalOverview,
} from "../../api";

type MonthlyAmount = {
  net: number;
  currency: MoneyAmountWithRequiredTaxCurrencyEnum;
};

export type AggregatedPrices = {
  [duration in RentContractTermDurationEnum]?: MonthlyAmount;
};

export function aggregate(
  availableRentalTerms: RentContractTerm[]
): AggregatedPrices | object {
  const monthlyAmountPerDuration: AggregatedPrices = {};

  availableRentalTerms?.forEach((term) => {
    const { duration, monthlyAmount } = term;
    const { net, currency } = monthlyAmount;

    if (monthlyAmountPerDuration[duration]) {
      monthlyAmountPerDuration[duration].net += net;
    } else {
      monthlyAmountPerDuration[duration] = { net, currency };
    }
  });

  return monthlyAmountPerDuration;
}

function adjustRentalTermsByQuantity(
  rentableProducts: CartProductRentalOverview[]
): RentContractTerm[] {
  return rentableProducts?.flatMap((rp) => {
    const { availableRentalTerms, quantity = 1 } = rp;

    return availableRentalTerms.map((art) => {
      const { duration, monthlyAmount } = art;
      const { net } = monthlyAmount;

      const quantityAdjustedNet = net * quantity;
      const quantityAdjustedMonthlyAmount = {
        ...monthlyAmount,
        net: quantityAdjustedNet,
      };
      const quantityAdjustedArt = {
        duration,
        monthlyAmount: quantityAdjustedMonthlyAmount,
      };
      return quantityAdjustedArt;
    });
  });
}

// ? This skews towards larger numbers for durations that exist, and smaller for durations that don't
// ? e.g. if we have 5 items, and 5 items have 12 months duration, but only 1 has 24 months duration,
// ?      then the 12 months duration will be 5x larger than the 24 months duration
export function useAggregatedPrices(
  rentalOverview?: CartRentalOverviewResult
): AggregatedPrices | undefined {
  if (!rentalOverview?.productPrices?.length) {
    return undefined;
  }

  const rentableProducts = rentalOverview.productPrices?.filter(
    (pp) => pp.hasRentalTerms
  );

  const quantityAdjustedRentalTerms =
    adjustRentalTermsByQuantity(rentableProducts);

  const aggregated = aggregate(quantityAdjustedRentalTerms);
  return Object.keys(aggregated).length > 0 ? aggregated : undefined;
}
