import { CartRentalOverviewResult } from "../../api";

import { useAggregatedPrices } from "./use-aggregated-prices";

/**
 * Return the lowest net price from the aggregated prices.
 *
 * Note: It may NOT return the net price of the longest tenure,
 * if that amount is higher than the net price of a shorter tenure.
 */
export function useLowestRentalPrice(
  rentalOverview: CartRentalOverviewResult
): number | undefined {
  const aggregatedPrices = useAggregatedPrices(rentalOverview);

  if (!aggregatedPrices) {
    return undefined;
  }

  const netPrices = Object.values(aggregatedPrices).map(
    (monthlyAmount) => monthlyAmount.net
  );
  const lowestRentalNetPrice = Math.min(...netPrices);
  return lowestRentalNetPrice;
}
