import {
  Container,
  TrustpilotLogo,
  TrustpilotRating,
} from "./trustpilot.styles";

function Trustpilot() {
  return (
    <Container>
      <TrustpilotLogo />
      <TrustpilotRating />
    </Container>
  );
}

export default Trustpilot;
