import { useEffect } from "preact/hooks";
import { options } from "preact";

const useCleanUpNestedFonts = () => {
  useEffect(() => {
    const cleanUpNestedFonts = () => {
      document.querySelectorAll("font").forEach((outerFont) => {
        const innerFont = outerFont.querySelector("font");
        if (innerFont) {
          // Move innerFont outside of outerFont
          outerFont.parentNode?.insertBefore(innerFont, outerFont);
          outerFont.remove();
        }

        // Remove any text nodes that follow the <font> element within the parent
        let { nextSibling } = outerFont;
        while (nextSibling && nextSibling.nodeType === Node.TEXT_NODE) {
          const textNode = nextSibling;
          nextSibling = nextSibling.nextSibling;
          textNode.remove();
        }
      });
    };

    // Hook into Preact's diffed lifecycle to clean up nested <font> elements
    const oldDiffedHook = options.diffed;
    options.diffed = (vnode) => {
      if (vnode.type === null) {
        cleanUpNestedFonts();
      }
      if (oldDiffedHook) {
        oldDiffedHook(vnode);
      }
    };

    // Initial cleanup call
    cleanUpNestedFonts();

    // Cleanup after initial page load
    document.addEventListener("DOMContentLoaded", cleanUpNestedFonts);

    return () => {
      // Restore the original diffed hook on unmount
      options.diffed = oldDiffedHook;
      document.removeEventListener("DOMContentLoaded", cleanUpNestedFonts);
    };
  }, []);
};

export default useCleanUpNestedFonts;
