/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PayNowContractTermFromJSON, PayNowContractTermToJSON, } from './PayNowContractTerm';
import { RentContractTermFromJSON, RentContractTermToJSON, } from './RentContractTerm';
/**
 * @export
 */
export const CalculatePriceSummaryAvailableContractTypesEnum = {
    PayNow: 'pay_now',
    Rent: 'rent'
};
/**
 * Check if a given object implements the CalculatePriceSummary interface.
 */
export function instanceOfCalculatePriceSummary(value) {
    let isInstance = true;
    isInstance = isInstance && "availableContractTypes" in value;
    isInstance = isInstance && "isSupported" in value;
    isInstance = isInstance && "summary" in value;
    return isInstance;
}
export function CalculatePriceSummaryFromJSON(json) {
    return CalculatePriceSummaryFromJSONTyped(json, false);
}
export function CalculatePriceSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availableContractTypes': json['available_contract_types'],
        'isSupported': json['is_supported'],
        'payNow': !exists(json, 'pay_now') ? undefined : PayNowContractTermFromJSON(json['pay_now']),
        'rent': !exists(json, 'rent') ? undefined : (json['rent'].map(RentContractTermFromJSON)),
        'summary': json['summary'],
    };
}
export function CalculatePriceSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'available_contract_types': value.availableContractTypes,
        'is_supported': value.isSupported,
        'pay_now': PayNowContractTermToJSON(value.payNow),
        'rent': value.rent === undefined ? undefined : (value.rent.map(RentContractTermToJSON)),
        'summary': value.summary,
    };
}
