import styled from "styled-components";

import { setTypographyImportant } from "$/ui/styles/mixins";
import {
  borderRadius,
  colors,
  sizes,
  spacing,
} from "$/ui/styles/tokens/tokens";

export const PriceAmount = styled.div<{
  primaryColor?: string;
  hidden?: boolean;
}>`
  ${setTypographyImportant("headline2")};
  font-size: ${sizes.sz036} !important;
  display: ${({ hidden }) => (hidden ? "none" : "inline-block")} !important;
  color: ${({ primaryColor }) => primaryColor ?? colors.topiBlue50} !important;
`;
export const PriceUnit = styled.div<{ primaryColor?: string }>`
  ${setTypographyImportant("headline2small")};
  display: ${({ hidden }) => (hidden ? "none" : "inline-block")} !important;
  color: ${({ primaryColor }) =>
    primaryColor ?? `${colors.topiBlue50}`} !important;
`;

export const EligibilityInfoPill = styled.div`
  ${setTypographyImportant("body2Emphasis")};
  background-color: ${colors.topiBlue10} !important;
  border-radius: ${borderRadius.l} !important;
  padding: ${spacing.sp004} ${spacing.sp008} !important;
  display: block !important;
`;

export const TaxNote = styled.div`
  ${setTypographyImportant("body2")};
  color: ${colors.grey60} !important;
  text-align: center !important;
`;

export const PriceTabPanel = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")} !important;
  flex-direction: column !important;
  gap: ${sizes.sz008} !important;
`;

export const Container = styled.div`
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;
