import { MarkupText, Text } from "preact-i18n";
import { useMemo, useState } from "preact/hooks";
import { Provider as ReduxProvider, useSelector } from "react-redux";

import { CartRentalOverviewResult } from "../../api";
import ErrorBoundary from "../../common/error-boundary/error-boundary";
import LogoBadge from "../../common/logo-badge/logo-badge";
import { Mode } from "../../common/models";
import Trustpilot from "../../common/trustpilot/trustpilot";
import I18nProvider from "../../i18n/i18n-provider";
import { getFetchedRentalOverviewForCart } from "../../slices/cart-slice";
import { getLocaleConfig, getThemeConfig } from "../../slices/config-slice";
import { getFetchedRentalOverviewForProduct } from "../../slices/pdp-slice";
import { store } from "../../store";
import { TenureOverview } from "../rental-overview/tenure-overview/tenure-overview";
import {
  CollapsableContainerFooter,
  CollapsableContainerHeader,
  CollapsableContainerItem,
  Container,
  Footer,
  Header,
  Icon,
  InnerContainer,
  Link,
  RatingOverview,
  SectionHeader,
  Subheader,
  TopiLogo,
} from "./learn-more-info.styles";
import useCleanUpNestedFonts from "$/use-cleanup-nested-fonts";

type LearnMoreInfoProps = {
  mode?: Mode;
};

function LearnMoreInfoImpl(props: LearnMoreInfoProps) {
  // Fix duplicated font nodes when using google translate
  useCleanUpNestedFonts();

  const { mode } = props;
  if (!mode || (mode !== "cart" && mode !== "product")) {
    // eslint-disable-next-line no-console
    console.error(
      "invalid/missing required attribute `mode` in the learn more info element. Valid values: 'cart', 'product'"
    );
  }

  const [expandedItems, setExpandedItems] = useState({});

  const localeConfig = useSelector(getLocaleConfig);
  const themeConfig = useSelector(getThemeConfig);
  const rentalOverviewForCart = useSelector(getFetchedRentalOverviewForCart);
  const rentalOverviewForProduct = useSelector(
    getFetchedRentalOverviewForProduct
  );

  let rentalOverview: CartRentalOverviewResult | undefined;
  if (mode === "cart") {
    rentalOverview = rentalOverviewForCart;
  } else if (mode === "product") {
    rentalOverview = rentalOverviewForProduct;
  }

  const faqLink = `https://www.topi.eu/${localeConfig}/faq`;

  const advantagesOfRentingList = useMemo(() => {
    const listItems = [
      "learnMoreCard.advantagesOfRentingCollapsed.0",
      "learnMoreCard.advantagesOfRentingCollapsed.1",
      "learnMoreCard.advantagesOfRentingCollapsed.2",
      "learnMoreCard.advantagesOfRentingCollapsed.3",
    ];
    const listItemsExpanded = [
      "learnMoreCard.advantagesOfRentingExpanded.0",
      "learnMoreCard.advantagesOfRentingExpanded.1",
      "learnMoreCard.advantagesOfRentingExpanded.2",
      "learnMoreCard.advantagesOfRentingExpanded.3",
    ];

    const toggleItem = (index) => {
      setExpandedItems({
        ...expandedItems,
        [index]: !expandedItems[index],
      });
    };

    return listItems.map((item, index) => (
      <CollapsableContainerItem key={index}>
        <CollapsableContainerHeader onClick={() => toggleItem(index)}>
          <Text id={item} />
          <Icon
            name={expandedItems[index] ? "caretUp" : "caretDown"}
            onTheLeft={true}
            color="grey"
          />
        </CollapsableContainerHeader>
        {expandedItems[index] && (
          <CollapsableContainerFooter>
            <MarkupText id={listItemsExpanded[index]} />
          </CollapsableContainerFooter>
        )}
      </CollapsableContainerItem>
    ));
  }, [expandedItems]);

  return rentalOverview ? (
    <I18nProvider locale={localeConfig}>
      <Container primaryColor={themeConfig?.primaryColor}>
        <Header>
          <LogoBadge nonFullWidth />
          <Text id="learnMoreCard.title" />
        </Header>
        <Subheader>
          <Text id="learnMoreCard.bodyText" />
        </Subheader>
        <TenureOverview rentalOverview={rentalOverview} />
        <RatingOverview>
          <TopiLogo />
          <Text id="learnMoreCard.rating.0" />
          <Trustpilot />
          <Text id="learnMoreCard.rating.1" />
        </RatingOverview>
        <InnerContainer data-testid="rental-advantages-list">
          <SectionHeader>
            <Text id="learnMoreCard.advantagesOfRentingCollapsed.title" />
          </SectionHeader>
          {advantagesOfRentingList}
        </InnerContainer>

        <Footer>
          <Text id="learnMoreCard.footer.message" />
          <Link href={faqLink} target="_blank">
            <Text id="learnMoreCard.footer.link" />
          </Link>
        </Footer>
      </Container>
    </I18nProvider>
  ) : undefined;
}

export default function LearnMoreInfo(props: LearnMoreInfoProps) {
  return (
    <>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <LearnMoreInfoImpl {...props} />
        </ErrorBoundary>
      </ReduxProvider>
    </>
  );
}
