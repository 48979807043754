import { createSlice } from "@reduxjs/toolkit";

// circular dependency may need fixing, but disabling linter works for now
// eslint-disable-next-line import/no-cycle
import { RootStateWidgets } from "../store";
import {
  ApiError,
  ApiResourceStatus,
  mapApiErrorToApiResourceStatus,
} from "../common/models";
import { CartRentalOverviewResult } from "../api";
import { CartItem } from "./catalog-models";
import { getRentalOverview } from "./common/rental-overview-thunk";

interface PdpState {
  getRentalOverviewStatus: ApiResourceStatus;
  getRentalOverviewError?: ApiError;
  rentalOverviewForProduct?: CartRentalOverviewResult;
  lastFetchTime?: number;
  lastInputPayload?: CartItem[];
}

const initialState: PdpState = {
  getRentalOverviewStatus: ApiResourceStatus.Stopped,
  getRentalOverviewError: undefined,
  rentalOverviewForProduct: undefined,
};

/* eslint-disable no-param-reassign */
export const pdpSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLastFetchTime: (state, action) => {
      state.lastFetchTime = action.payload;
    },
    setLastInputPayload: (state, action) => {
      state.lastInputPayload = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getRentalOverview.pending, (state, { meta }) => {
      if (meta.arg.slice !== "pdp") {
        return;
      }
      state.getRentalOverviewStatus = ApiResourceStatus.Pending;
      state.getRentalOverviewError = undefined;
    });
    builder.addCase(getRentalOverview.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.slice !== "pdp") {
        return;
      }
      state.getRentalOverviewStatus = ApiResourceStatus.Found;
      state.rentalOverviewForProduct = payload;
      state.getRentalOverviewError = undefined;

      state.lastFetchTime = Date.now();
      state.lastInputPayload = meta.arg.cartItems;
    });
    builder.addCase(getRentalOverview.rejected, (state, { payload, meta }) => {
      if (meta.arg.slice !== "pdp") {
        return;
      }
      const apiError = payload as ApiError;
      state.getRentalOverviewStatus = mapApiErrorToApiResourceStatus(apiError);
      state.getRentalOverviewError = apiError;
    });
  },
});

export const getFetchedRentalOverviewForProduct = (
  state: RootStateWidgets
): CartRentalOverviewResult | undefined => state.pdp.rentalOverviewForProduct;

export default pdpSlice.reducer;
