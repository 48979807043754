/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * Check if a given object implements the CheckSupportedRequestBody interface.
 */
export function instanceOfCheckSupportedRequestBody(value) {
    let isInstance = true;
    isInstance = isInstance && "sellerProductReferences" in value;
    return isInstance;
}
export function CheckSupportedRequestBodyFromJSON(json) {
    return CheckSupportedRequestBodyFromJSONTyped(json, false);
}
export function CheckSupportedRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sellerProductReferences': (json['seller_product_references'].map(SellerProductReferenceFromJSON)),
    };
}
export function CheckSupportedRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'seller_product_references': (value.sellerProductReferences.map(SellerProductReferenceToJSON)),
    };
}
