/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CartProductRentalOverviewFromJSON, CartProductRentalOverviewToJSON, } from './CartProductRentalOverview';
/**
 * @export
 */
export const CartRentalOverviewResultCurrencyEnum = {
    Eur: 'EUR'
};
/**
 * Check if a given object implements the CartRentalOverviewResult interface.
 */
export function instanceOfCartRentalOverviewResult(value) {
    let isInstance = true;
    return isInstance;
}
export function CartRentalOverviewResultFromJSON(json) {
    return CartRentalOverviewResultFromJSONTyped(json, false);
}
export function CartRentalOverviewResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'canCheckout': !exists(json, 'can_checkout') ? undefined : json['can_checkout'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'productPrices': !exists(json, 'product_prices') ? undefined : (json['product_prices'].map(CartProductRentalOverviewFromJSON)),
        'totalRentalAmount': !exists(json, 'total_rental_amount') ? undefined : json['total_rental_amount'],
        'totalRentalSummary': !exists(json, 'total_rental_summary') ? undefined : json['total_rental_summary'],
    };
}
export function CartRentalOverviewResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'can_checkout': value.canCheckout,
        'currency': value.currency,
        'product_prices': value.productPrices === undefined ? undefined : (value.productPrices.map(CartProductRentalOverviewToJSON)),
        'total_rental_amount': value.totalRentalAmount,
        'total_rental_summary': value.totalRentalSummary,
    };
}
