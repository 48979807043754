/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContractTermsSummaryFromJSON, ContractTermsSummaryToJSON, } from './ContractTermsSummary';
import { MoneyAmountFromJSON, MoneyAmountToJSON, } from './MoneyAmount';
import { SellerProductReferenceFromJSON, SellerProductReferenceToJSON, } from './SellerProductReference';
/**
 * Check if a given object implements the SearchProductResult interface.
 */
export function instanceOfSearchProductResult(value) {
    let isInstance = true;
    isInstance = isInstance && "availableContractTerms" in value;
    isInstance = isInstance && "listPrice" in value;
    isInstance = isInstance && "manufacturer" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "sellerProductReferences" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function SearchProductResultFromJSON(json) {
    return SearchProductResultFromJSONTyped(json, false);
}
export function SearchProductResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'availableContractTerms': ContractTermsSummaryFromJSON(json['available_contract_terms']),
        'baseRentalPrice': !exists(json, 'base_rental_price') ? undefined : MoneyAmountFromJSON(json['base_rental_price']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'listPrice': MoneyAmountFromJSON(json['list_price']),
        'manufacturer': json['manufacturer'],
        'mpn': !exists(json, 'mpn') ? undefined : json['mpn'],
        'origin': json['origin'],
        'prettyId': !exists(json, 'pretty_id') ? undefined : json['pretty_id'],
        'productId': json['product_id'],
        'reference': json['reference'],
        'sellerProductReferences': (json['seller_product_references'].map(SellerProductReferenceFromJSON)),
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'title': json['title'],
    };
}
export function SearchProductResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'archived': value.archived,
        'available_contract_terms': ContractTermsSummaryToJSON(value.availableContractTerms),
        'base_rental_price': MoneyAmountToJSON(value.baseRentalPrice),
        'description': value.description,
        'ean': value.ean,
        'list_price': MoneyAmountToJSON(value.listPrice),
        'manufacturer': value.manufacturer,
        'mpn': value.mpn,
        'origin': value.origin,
        'pretty_id': value.prettyId,
        'product_id': value.productId,
        'reference': value.reference,
        'seller_product_references': (value.sellerProductReferences.map(SellerProductReferenceToJSON)),
        'subtitle': value.subtitle,
        'title': value.title,
    };
}
