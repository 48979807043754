import { useState, useEffect } from "preact/hooks";
import { RentContractTermDurationEnum } from "../../api";

type UseTenureTogglesReturn = [
  RentContractTermDurationEnum | undefined,
  (tenure: RentContractTermDurationEnum) => void,
];

export function useTenureToggles(
  availableRentDurations: RentContractTermDurationEnum[]
): UseTenureTogglesReturn {
  const [tenure, setTenure] = useState<
    RentContractTermDurationEnum | undefined
  >();

  useEffect(() => {
    // should run only once, when availableRentDurations is defined
    if (availableRentDurations?.length === 0 || tenure !== undefined) {
      return;
    }

    const lastIndex = availableRentDurations.length - 1;
    const defaultTenure = availableRentDurations[lastIndex];

    setTenure(defaultTenure);
  }, [availableRentDurations, tenure]);

  return [tenure, setTenure];
}
