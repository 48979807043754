/* eslint-disable no-console */
import { Text } from "preact-i18n";
import { Provider as ReduxProvider, useSelector } from "react-redux";

import ErrorBoundary from "../../common/error-boundary/error-boundary";
import { Mode } from "../../common/models";
import I18nProvider from "../../i18n/i18n-provider";
import { getFetchedRentalOverviewForCart } from "../../slices/cart-slice";
import { getLocaleConfig } from "../../slices/config-slice";
import { getFetchedRentalOverviewForProduct } from "../../slices/pdp-slice";
import { store } from "../../store";
import { ProductModeButton, CartModeButton } from "./checkout-button.styles";
import useCleanUpNestedFonts from "$/use-cleanup-nested-fonts";

interface CheckoutButtonProps {
  checkoutMode: Mode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CheckoutButtonImpl(props: CheckoutButtonProps) {
  // Fix duplicated font nodes when using google translate
  useCleanUpNestedFonts();

  const { checkoutMode } = props;

  if (!checkoutMode) {
    console.error(
      "invalid/missing required attribute `checkout-mode` in the checkout button element. Valid values: 'cart', 'product'"
    );
  }

  const rentalOverviewForCart = useSelector(getFetchedRentalOverviewForCart);
  const rentalOverviewForPdp = useSelector(getFetchedRentalOverviewForProduct);
  const localeConfig = useSelector(getLocaleConfig);

  let canCheckout = false;
  if (checkoutMode === "cart") {
    canCheckout = rentalOverviewForCart?.canCheckout;
  } else if (checkoutMode === "product") {
    canCheckout = rentalOverviewForPdp?.canCheckout;
  }

  let button;
  if (checkoutMode === "cart") {
    button = (
      <CartModeButton type="button">
        <Text id="checkoutButton.cartModeText" />
      </CartModeButton>
    );
  } else if (checkoutMode === "product") {
    button = (
      <ProductModeButton>
        <Text id="checkoutButton.productModeText" />
      </ProductModeButton>
    );
  }

  return (
    <I18nProvider locale={localeConfig}>
      {canCheckout ? button : undefined}
    </I18nProvider>
  );
}

export default function CheckoutButton(props: CheckoutButtonProps) {
  return (
    <>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <CheckoutButtonImpl {...props} />
        </ErrorBoundary>
      </ReduxProvider>
    </>
  );
}
