/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MoneyAmountWithRequiredTaxFromJSON, MoneyAmountWithRequiredTaxToJSON, } from './MoneyAmountWithRequiredTax';
/**
 * @export
 */
export const RentContractTermDurationEnum = {
    NUMBER_6: 6,
    NUMBER_12: 12,
    NUMBER_24: 24,
    NUMBER_36: 36
};
/**
 * Check if a given object implements the RentContractTerm interface.
 */
export function instanceOfRentContractTerm(value) {
    let isInstance = true;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "monthlyAmount" in value;
    return isInstance;
}
export function RentContractTermFromJSON(json) {
    return RentContractTermFromJSONTyped(json, false);
}
export function RentContractTermFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'duration': json['duration'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'monthlyAmount': MoneyAmountWithRequiredTaxFromJSON(json['monthly_amount']),
    };
}
export function RentContractTermToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'duration': value.duration,
        'id': value.id,
        'monthly_amount': MoneyAmountWithRequiredTaxToJSON(value.monthlyAmount),
    };
}
