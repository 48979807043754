/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomProductFromJSON, CustomProductToJSON, } from './CustomProduct';
/**
 * Check if a given object implements the AddCustomProductRequestBody interface.
 */
export function instanceOfAddCustomProductRequestBody(value) {
    let isInstance = true;
    isInstance = isInstance && "product" in value;
    return isInstance;
}
export function AddCustomProductRequestBodyFromJSON(json) {
    return AddCustomProductRequestBodyFromJSONTyped(json, false);
}
export function AddCustomProductRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'product': CustomProductFromJSON(json['product']),
    };
}
export function AddCustomProductRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'product': CustomProductToJSON(value.product),
    };
}
