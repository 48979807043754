import { createAsyncThunk } from "@reduxjs/toolkit";

// circular dependency may need fixing, but disabling linter works for now
// eslint-disable-next-line import/no-cycle
import { RootStateWidgets } from "../../store";
import { ApiResourceStatus, Slice } from "../../common/models";
import { ApiType, RequiredError, ResponseError } from "../../api";
import { CartItem } from "../catalog-models";

import { getLocaleConfig, getWidgetIdConfig } from "../config-slice";

function getSliceState(state: RootStateWidgets, slice: Slice) {
  const sliceState = state[slice];
  return {
    lastFetchTime: sliceState.lastFetchTime,
    lastInputPayload: sliceState.lastInputPayload,
    getRentalOverviewStatus: sliceState.getRentalOverviewStatus,
  };
}

type GetRentalOverviewPayload = {
  cartItems: CartItem[];
  slice: Slice;
};

export const getRentalOverview = createAsyncThunk(
  "widget-api/getRentalOverview",
  async (
    { cartItems }: GetRentalOverviewPayload,
    { extra, rejectWithValue, getState }
  ) => {
    const localeConfig = getLocaleConfig(getState() as RootStateWidgets);
    const widgetIdConfig = getWidgetIdConfig(getState() as RootStateWidgets);
    const widgetApi = (extra as ApiType).sellerApi.catalog;

    try {
      const cartRentalOverview =
        await widgetApi.catalogWidgetsCartRentalOverview(
          {
            widgetsCartRentalOverviewRequestBody: {
              widgetId: widgetIdConfig,
              products: cartItems,
            },
          },
          // Extend the headers with Accept-Language.
          // This is done here instead of the api client because the api client
          // doesn't have access to the locale on first load.
          (requestContext) => {
            const newInit = {
              ...requestContext.init,
              headers: {
                ...requestContext.init.headers,
                "Accept-Language": localeConfig,
              },
            };
            return Promise.resolve(newInit);
          }
        );
      return cartRentalOverview;
    } catch (error: unknown) {
      if (error instanceof ResponseError) {
        return rejectWithValue({
          code: error.response.status,
          message: error.message,
        });
      }
      if (error instanceof RequiredError) {
        return rejectWithValue({
          code: 400,
          message: error.toString(),
        });
      }
      return rejectWithValue({
        code: 422,
        message: "Unprocessable Entity",
      });
    }
  },
  {
    condition: ({ cartItems, slice }, { getState }) => {
      const ONE_MINUTE = 60 * 1000;

      const state = getState() as RootStateWidgets;

      const { lastFetchTime, lastInputPayload, getRentalOverviewStatus } =
        getSliceState(state, slice);

      const currentTime = Date.now();

      const isFetchInFlight =
        getRentalOverviewStatus === ApiResourceStatus.Pending;

      const inputsChanged =
        JSON.stringify(lastInputPayload) !== JSON.stringify(cartItems);
      const shouldExpireCache = currentTime - lastFetchTime > ONE_MINUTE;

      return !isFetchInFlight && (inputsChanged || shouldExpireCache);
    },
  }
);
