/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ProductRentContractFromJSON, ProductRentContractToJSON, } from './ProductRentContract';
/**
 * Check if a given object implements the ContractTermsSummary interface.
 */
export function instanceOfContractTermsSummary(value) {
    let isInstance = true;
    isInstance = isInstance && "canPayNow" in value;
    isInstance = isInstance && "canRent" in value;
    return isInstance;
}
export function ContractTermsSummaryFromJSON(json) {
    return ContractTermsSummaryFromJSONTyped(json, false);
}
export function ContractTermsSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'canPayNow': json['can_pay_now'],
        'canRent': json['can_rent'],
        'rent': !exists(json, 'rent') ? undefined : ProductRentContractFromJSON(json['rent']),
    };
}
export function ContractTermsSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'can_pay_now': value.canPayNow,
        'can_rent': value.canRent,
        'rent': ProductRentContractToJSON(value.rent),
    };
}
