/* eslint-disable default-case */
import styled, { css } from "styled-components";
import {
  borderRadius,
  colors,
  spacing,
  sizes,
} from "$/ui/styles/tokens/tokens";
import { setTypography } from "$/ui/styles/mixins";
import { Icon as DefaultIcon } from "$/ui/components/icon/icon";
import type { CardProps } from "./card";
import { from } from "$/ui/styles/media-queries";

type CardContainerProps = Required<Pick<CardProps, "type">> & {
  hasIcon: boolean;
};

export const Container = styled.section<CardContainerProps>`
  padding: var(--card-padding);
  background: var(--card-bg-color);
  border-radius: var(--card-radius);

  display: grid;
  gap: ${spacing.sp016};
  grid-template-columns: var(--card-grid-columns);
  grid-template-rows: var(--card-grid-rows);

  ${({ type, hasIcon }) => {
    switch (type) {
      case "primary": {
        return css`
          --card-bg-color: ${colors.white};
          --card-radius: ${borderRadius.xl};
          --card-padding: ${spacing.sp016};
          --card-grid-columns: none;
          --card-grid-rows: none;
          --card-content-align: top;
          ${setTypography("body1")}
        `;
      }
      case "secondary": {
        return css`
          --card-bg-color: ${colors.grey04};
          --card-radius: ${borderRadius.m};
          --card-padding: ${spacing.sp016};
          --card-grid-columns: ${hasIcon ? `auto 1fr` : `none`};
          --card-grid-rows: none;
          --card-content-align: center;
          ${setTypography("body2Emphasis")}
        `;
      }
    }
    return "";
  }};

  ${from.m} {
    ${({ type }) => {
      switch (type) {
        case "primary": {
          return css`
            --card-padding: ${spacing.sp024};
          `;
        }
      }
      return "";
    }}
  }
`;

export const Content = styled.div`
  align-self: var(--card-content-align);
  flex: 1;
`;

export const Icon = styled(DefaultIcon)`
  width: ${sizes.sz024};
  height: ${sizes.sz024};
  align-self: center;
  flex-shrink: 0;
`;
