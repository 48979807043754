/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable no-console */
import { VNode } from "preact";
import { useErrorBoundary } from "preact/hooks";

interface TopiWidgetsErrorBoundaryProps {
  children: VNode;
}

const ErrorBoundary = ({
  children,
}: TopiWidgetsErrorBoundaryProps): VNode | undefined => {
  // TODO PHNX-339: Track production widget errors somewhere
  const [error] = useErrorBoundary();

  if (error) {
    console.error(error);
    return undefined;
  }

  return children;
};

export default ErrorBoundary;
