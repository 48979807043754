import { FC, ReactNode } from "react";
import type { IconName } from "$/ui/components/icon/icon";
import { Container, Content, Icon } from "./card.styles";

type CommonProps = {
  className?: string;
  children?: ReactNode;
  testId?: string;
};

type PrimaryOrSecondaryCardProps =
  | {
      // primary cards can have optional headers
      type?: "primary";
      icon?: never;
    }
  | {
      // secondary cards can have optional icons
      type: "secondary";
      header?: never;
      icon?: IconName;
    };

export type CardProps = CommonProps & PrimaryOrSecondaryCardProps;

export const Card: FC<CardProps> = ({
  className,
  children,
  type = "primary",
  icon,
  testId,
}) => {
  return (
    <Container
      className={className}
      type={type}
      hasIcon={!!icon}
      data-testid={testId}
    >
      {icon ? <Icon name={icon} /> : undefined}
      <Content>{children}</Content>
    </Container>
  );
};
