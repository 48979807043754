import { h, VNode } from "preact";

import CaretDown from "$/ui/assets/svgs/icons/caret-down.svg";
import CaretUp from "$/ui/assets/svgs/icons/caret-up.svg";
import CheckCircle from "$/ui/assets/svgs/icons/check-circle.svg";
import Cross from "$/ui/assets/svgs/icons/cross.svg";
import NumberCircleFive from "$/ui/assets/svgs/icons/number-circle-five.svg";
import NumberCircleFour from "$/ui/assets/svgs/icons/number-circle-four.svg";
import NumberCircleOne from "$/ui/assets/svgs/icons/number-circle-one.svg";
import NumberCircleThree from "$/ui/assets/svgs/icons/number-circle-three.svg";
import NumberCircleTwo from "$/ui/assets/svgs/icons/number-circle-two.svg";

import { Svg } from "./icon.styles";

export const iconTypes = {
  caretDown: CaretDown,
  caretUp: CaretUp,
  cross: Cross,
  checkCircle: CheckCircle,
  numberCircleOne: NumberCircleOne,
  numberCircleTwo: NumberCircleTwo,
  numberCircleThree: NumberCircleThree,
  numberCircleFour: NumberCircleFour,
  numberCircleFive: NumberCircleFive,
} as const;

export type IconName = keyof typeof iconTypes;

export interface IconProps {
  title?: string;
  className?: string;
  name: IconName;
}

export const Icon = ({
  title,
  className,
  name,
}: IconProps): VNode | undefined => {
  const IconType = name ? iconTypes[name] : undefined;

  if (!IconType) {
    return undefined;
  }

  return <Svg as={IconType} className={className} title={title} />;
};
