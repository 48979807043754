/* tslint:disable */
/* eslint-disable */
/**
 * topi Seller API
 * # Localization  Strings returned can be localized when the _Accept-Language_ header is provided in the request. It should follow the [RFC-2616 convention](http://www.ietf.org/rfc/rfc2616.txt).
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CategorisationIdentifiersFromJSON, CategorisationIdentifiersToJSON, } from './CategorisationIdentifiers';
import { MoneyAmountWithOptionalGrossFromJSON, MoneyAmountWithOptionalGrossToJSON, } from './MoneyAmountWithOptionalGross';
/**
 * Check if a given object implements the OfferParsingUnproccesedItem interface.
 */
export function instanceOfOfferParsingUnproccesedItem(value) {
    let isInstance = true;
    return isInstance;
}
export function OfferParsingUnproccesedItemFromJSON(json) {
    return OfferParsingUnproccesedItemFromJSONTyped(json, false);
}
export function OfferParsingUnproccesedItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'categorisationIdentifiers': !exists(json, 'CategorisationIdentifiers') ? undefined : CategorisationIdentifiersFromJSON(json['CategorisationIdentifiers']),
        'inputDescription': !exists(json, 'input_description') ? undefined : json['input_description'],
        'inputPrice': !exists(json, 'input_price') ? undefined : MoneyAmountWithOptionalGrossFromJSON(json['input_price']),
        'inputQuantity': !exists(json, 'input_quantity') ? undefined : json['input_quantity'],
        'inputReference': !exists(json, 'input_reference') ? undefined : json['input_reference'],
        'inputTitle': !exists(json, 'input_title') ? undefined : json['input_title'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
    };
}
export function OfferParsingUnproccesedItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'CategorisationIdentifiers': CategorisationIdentifiersToJSON(value.categorisationIdentifiers),
        'input_description': value.inputDescription,
        'input_price': MoneyAmountWithOptionalGrossToJSON(value.inputPrice),
        'input_quantity': value.inputQuantity,
        'input_reference': value.inputReference,
        'input_title': value.inputTitle,
        'manufacturer': value.manufacturer,
    };
}
