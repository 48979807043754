import { Container, Logo } from "./logo-badge.styles";

interface LogoBadgeProps {
  children?: JSX.Element;
  nonFullWidth?: boolean;
  className?: string;
}

function LogoBadge(props: LogoBadgeProps) {
  const { children, nonFullWidth, className } = props;

  return (
    <Container
      className={className}
      nonFullWidth={nonFullWidth}
      hasChildren={!!children}
    >
      {children}
      <Logo hasChildren={!!children} />
    </Container>
  );
}

export default LogoBadge;
