import { MarkupText, Text } from "preact-i18n";
import { useState } from "preact/hooks";
import { Provider as ReduxProvider, useSelector } from "react-redux";

import ErrorBoundary from "../../common/error-boundary/error-boundary";
import LogoBadge from "../../common/logo-badge/logo-badge";
import { Mode } from "../../common/models";
import I18nProvider from "../../i18n/i18n-provider";
import { getFetchedRentalOverviewForCart } from "../../slices/cart-slice";
import { getLocaleConfig } from "../../slices/config-slice";
import { store } from "../../store";
import { useMoneyAmount } from "../hooks/use-money-amount";
import { LearnMoreModal } from "../common/learn-more-modal/learn-more-modal";
import {
  Container,
  Item,
  LinkButton,
  TextAndLogo,
} from "./cart-rental-summary-label.styles";
import { useDebugMessages } from "./hooks/use-debug-messages";
import { useProductModeRentalAmount } from "./hooks/use-product-mode-rental-amount";
import useCleanUpNestedFonts from "$/use-cleanup-nested-fonts";

type CartRentalSummaryLabelProps = {
  mode: Mode;
  reference?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CartRentalSummaryLabelImpl(props: CartRentalSummaryLabelProps) {
  // Fix duplicated font nodes when using google translate
  useCleanUpNestedFonts();

  const { mode, reference } = props;

  useDebugMessages(mode, reference);
  const rentalOverviewForCart = useSelector(getFetchedRentalOverviewForCart);
  const localeConfig = useSelector(getLocaleConfig);
  const totalRentalAmountForProduct = useProductModeRentalAmount(
    mode,
    rentalOverviewForCart,
    reference
  );
  const totalRentalAmountForCart =
    rentalOverviewForCart?.totalRentalAmount ?? undefined;

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = (event: Event) => {
    event.preventDefault();
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  let totalRentalAmount: number | undefined;
  if (mode === "product") {
    totalRentalAmount = totalRentalAmountForProduct;
  } else if (mode === "cart") {
    totalRentalAmount = totalRentalAmountForCart;
  }

  const price = useMoneyAmount(totalRentalAmount, "EUR");

  return price ? (
    <I18nProvider locale={localeConfig}>
      <Container>
        <Item>
          <MarkupText id="rentalSummaryLabel.rentFrom" fields={{ price }} />
        </Item>
        <Item>
          <TextAndLogo>
            <Text id="rentalSummaryLabel.rentWith" />
            <LogoBadge nonFullWidth />
          </TextAndLogo>
        </Item>
        {mode === "cart" ? (
          <LinkButton onClick={openModal} type="button">
            <Text id="learnMore" />
          </LinkButton>
        ) : undefined}
      </Container>
      {isModalOpen && <LearnMoreModal onClose={closeModal} mode="cart" />}
    </I18nProvider>
  ) : undefined;
}

export default function CartRentalSummaryLabel(
  props: CartRentalSummaryLabelProps
) {
  return (
    <>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <CartRentalSummaryLabelImpl {...props} />
        </ErrorBoundary>
      </ReduxProvider>
    </>
  );
}
